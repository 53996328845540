import "./child_profile_page.css";
import { useNavigate, useParams } from "react-router-dom";
import { imageUrl } from "../../services/api";
import { URLS } from "../../constants";
import Cardcomponent from "./Cardcomponent";
import "react-toastify/dist/ReactToastify.css";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useState, useEffect } from "react";
import { PieChart, Pie, Sector } from "recharts";
import InviteFriendpopup from "../Common.WebAppMate/InviteFriendpopup";
import ProfileSharepopup from "../ViewProfile.WebAppMate/ProfileSharepopup";
import { makePostRequest, makeGetRequest } from "../../services/api";
import { Multiselect } from "multiselect-react-dropdown";
import FourDotsLoader from "../Common.WebAppMate/FourDoted";
import { decodeToken, style, toastifyMessage } from "../../utilities/CustomFunctions";
import { CategoryOptions } from "../SavedResources.WebAppMate/SaveResourceList";
import moment from "moment";
import { toSeoUrl } from "../../services/common.services";
const ExcelJS = require('exceljs');
const URL = process.env.REACT_APP_URL;
// /*
//      * Childprofileupdateview function is used to do view the child profile
//      * and allow user to share , delete the profile. It also allows user
//      * to invite friends to other user.
//      * @author Naresh Kumar <naresh.gulati@webappmate.com>
//      * created on: 05-5-2023
//      * @author krishna dobariya <krishna.d@webappmate.com>
//      * /
export const statusData = [
  { name: "In Progress", value: "In Progress" },
  { name: "Past", value: "Past" },
  { name: "Future", value: "For the future" },
]
export const viewResult = [
  { name: "Viewing 9", value: "9" },
  { name: "Viewing 12", value: "12" },
]
function Childprofileupdateview() {
  const parmas = useParams();
  let userData = decodeToken();
  const navigate = useNavigate();
  const [id, setId] = useState("");
  const [resourceData, setresourcesData] = useState([]);
  const [childResourcePercentage, setchildResourcePercentage] = useState("");;
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [open1, setOpen1] = useState(false);
  const handleClose1 = () => setOpen1(false);
  const [data, setData] = useState([]);
  const [resourcelength, setresourcelength] = useState();
  const [spin, setSpin] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [childImage, setChildImage] = useState("");
  const [RoportPopup, setReportPopup] = useState(false);
  const [sharpermision, setsharpermision] = useState(false);
  const [category, setcategory] = useState("");
  const [status, setstatus] = useState("");
  const [keyword, setkeyword] = useState("");
  const [limit, setlimit] = useState(9);
  const [gradeLevelData, setGradeLevelData] = useState([]);
  const [gradeRange, setGradeRange] = useState([]);
  const [pageNum, setPageNum] = useState(1);
  const [remaining, setRemaining] = useState(true)

  let useractive = localStorage.getItem("useractive");
  let uimage = imageUrl;

  const getGradeLevelData = () => {
    makeGetRequest(URLS.usergetallgradelevel, null, null, null)
      .then((res) => {
        if (res.code === 200) {
          setGradeLevelData(res.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  //api to fetch child  data
  const getchildprofile = () => {
    window.scrollTo({
      bottom: 0,
      behavior: "smooth",
    });
    let data = {
      childId: id,
    };
    makePostRequest(URLS.getonechild, null, data, null).then((response) => {
      setFirstName(
        response?.data && response.data.firstName ? response.data.firstName : ""
      );
      setChildImage(uimage + response?.data?.photo);
      setSpin(false);
    });
  };
  //functions to fetch child saved resources data
  const getresources = () => {
    setSpin(true);
    const data = {
      user_id: userData.userAuth.id,
      child_id: id,
      storing_type: category,
      grade: gradeRange,
      status: status,
      searchKey: keyword,
      page_number: pageNum,
      item_per_page: parseInt(limit),
      exports_key: "no"
    };
    makePostRequest(URLS.getchildresource, null, data)
      .then((response) => {
        setSpin(false);
        const saveSet = new Set(response?.data?.save?.map((val) => val.resource_id));
        const updatedResourceData = response?.data?.resourceData.map((resource) => ({
          ...resource,
          save: saveSet.has(resource.resource_id) ? "yes" : "no"
        }));
        setresourcesData(pageNum !== 1 ? [...resourceData, ...updatedResourceData] : updatedResourceData);
        if (response.data.view_profile !== false) {
          getchildprofile();
        }
        setresourcelength(response.data.resourceData?.length);
        setchildResourcePercentage(response?.data?.childResourcePercentage);
        setRemaining(response?.data?.childResourcePercentage?.remaining_count !== 0);
      })
      .catch((error) => {
        console.error("Error fetching resources:", error);
        setSpin(false);
      });
  };

  const preparechartdata = () => {
    var temp = [];
    temp.push({
      name: "Heart",
      students: parseFloat(childResourcePercentage?.heart_percentage),
      fill: "#265472",
      unit: "%",
    });
    temp.push({
      name: "Mind",
      students: parseFloat(childResourcePercentage?.mind_percentage),
      fill: "#F19357",
      unit: "%",
    });
    temp.push({
      name: "Soul",
      students: parseFloat(childResourcePercentage?.soul_percentage),
      fill: "#F0B285",
      unit: "%",
    });
    temp.push({
      name: "Strength",
      students: parseFloat(childResourcePercentage?.streangth_percentage),
      fill: "#AAC0CE",
      unit: "%",
    });
    setData(temp);
    setSpin(false);
  };

  const fillonecolor = () => {
    var temp = [];
    temp.push({ name: "Heart", students: 0, fill: "grey" });
    temp.push({ name: "Mind", students: 0, fill: "grey" });
    temp.push({ name: "Soul", students: 0, fill: "grey" });
    temp.push({ name: "Strength", students: 0, fill: "grey" });
    setData(temp);
    setSpin(false);
  };

  // handle functions
  const handleGrade = (selectedList) => {
    let arr = [];
    selectedList?.forEach((val, i) => {
      arr.push(val.id);
    });
    setGradeRange(arr);
  };
  //API to delete child
  const deletechild = () => {
    let data = {
      id: id,
    };
    makePostRequest(URLS.deleteChild, null, data, null)
      .then((res) => {
        if (res.code === 200) {
          toastifyMessage(res.message, "success");
        }
        setOpen(false);
        setTimeout(() => {
          navigate("/search-page");
        }, 1000);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  useEffect(() => {
    getGradeLevelData();
    document.body.classList.add("profilepage");
    return () => {
      document.body.classList.remove("profilepage");
    };
    // eslint-disable-next-line
  }, []);

  // useEffects calls
  useEffect(() => {
    setId(parmas.id);
  }, [parmas]);

  useEffect(() => {
    useractive === "" && navigate("/sign-in");
    id && getresources();
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (childResourcePercentage?.total_resource_count !== 0) {
      preparechartdata();
    } else {
      fillonecolor();
    }
    // eslint-disable-next-line
  }, [childResourcePercentage]);

  useEffect(() => {
    id && getresources()
    // eslint-disable-next-line
  }, [pageNum])

  useEffect(() => {
    if (id) {
      getresources()
      setresourcesData([])
    }
    // eslint-disable-next-line
  }, [limit])

  // search handler function
  const handleSearch = () => {
    if (pageNum === 1) {
      getresources()
    } else {
      setPageNum(1)
    }
  }
  // this function use for xsl sheet
  const ExportEcelFile = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("ResourceData");
    sheet.properties.defaultRowHeight = 30;
    sheet.getRow(1).font = {
      size: 10,
      bold: true,
    }

    const columns = [
      { header: "S.No", key: 'S_No', width: 5 },
      { header: "DATE", key: 'DATE', width: 15 },
      { header: "OWNER NAME", key: 'owner', width: 20 },
      { header: "EMAIL", key: 'email', width: 20 },
      { header: "RESOURCE TYPE", key: 'RESOURCE_TYPE', width: 20 },
      { header: "TITLE", key: 'resource_title', width: 20 },
      { header: "DESCRIPTION", key: "description", width: 20 },
      { header: "($)PRICE", key: 'Price', width: 10 },
      { header: "URL", key: "url", width: 55 } // Set a reasonable default width
    ];

    sheet.columns = columns;
    let data = {
      "user_id": userData.userAuth.id,
      "child_id": id,
      "storing_type": category,
      "grade": gradeRange,
      "status": status,
      "searchKey": keyword,
      "page_number": 1,
      "item_per_page": parseInt(limit),
      "exports_key": "yes"
    }
    setSpin(true)
    makePostRequest(URLS.getchildresource, null, data, null)
      .then((response) => {
        const maxUrlLength = Math.max(
          ...response?.data?.resourceData?.map(val => (`${URL}/full-description/${val?.resource_id}/${toSeoUrl(val?.tbl_user_resource?.resource_title)}` || '').length)
        );
        sheet.getColumn('url').width = maxUrlLength + 5; // Adding a margin for better readability
        response?.data?.resourceData?.forEach((val, i) => {
          sheet.addRow({
            S_No: (i + 1),
            DATE: moment(val?.created_at).format("MM-DD-YYYY"),
            owner: val?.owner?.firstName ? val?.owner?.firstName + " " + val?.owner?.lastName : "--",
            email: val?.owner?.email ? val?.owner?.email : "--",
            RESOURCE_TYPE: val?.tbl_user_resource?.tbl_resource_type_master?.name ?
              val?.tbl_user_resource?.tbl_resource_type_master?.name
              : "--",
            resource_title: val?.tbl_user_resource?.resource_title ? val?.tbl_user_resource?.resource_title : "--",
            description: val?.tbl_user_resource?.description ? val?.tbl_user_resource?.description : "--",
            Price: val?.tbl_user_resource?.purchase_price ? "$" + val.tbl_user_resource?.purchase_price.toFixed(2) : "--",
            url: `${URL}/full-description/${val?.resource_id}/${toSeoUrl(val?.tbl_user_resource?.resource_title)}`
          });
        });

        // Adding hyperlinks to the 'URL' column
        sheet.eachRow({ includeEmpty: false }, function (row, rowNumber) {
          const cell = row.getCell('url');
          cell.value = { text: cell.text, hyperlink: cell.text };
          cell.font = { color: { rgb: '000000' }, underline: true };
        });

        // Save the workbook to a file or send it as a response
        workbook.xlsx.writeBuffer().then(data => {
          const blob = new Blob([data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheet.sheet",
          });
          const url = window.URL.createObjectURL(blob);
          const anchor = document.createElement('a');
          anchor.href = url;
          anchor.download = 'resource.xlsx';
          anchor.click();
          window.URL.revokeObjectURL(url);
        });
        setSpin(false)
      })
  }

  // handle to active tab
  const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, value } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 5) * cos;
    const sy = cy + (outerRadius + 5) * sin;
    const mx = cx + (outerRadius + 10) * cos;
    const my = cy + (outerRadius + 10) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 20;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
      <g>
        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
          {payload.name}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 2}
          outerRadius={outerRadius + 4}
          fill={fill}
        />
        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${value} %`}</text>
      </g>
    );
  };

  const [activeIndex, setActiveIndex] = useState('');

  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  };
  return (
    <>
      {
        spin && <div className="sharepopupOuter backgroundOpacity1">
          <FourDotsLoader />
        </div>
      }
      <div className="col-lg-10">
        <div className="row  margint">
          <div className="col-sm-9 col-md-9 col-lg-9 ">
            <div className="profile-page-card">
              <div className={`graph-img showpiePercentage ${(childResourcePercentage.total_resource_count_percentage !== 0) ? "whiteAddborder" : "blackAddBorder"}`}>
                <PieChart width={200} height={200} >
                  <Pie data={data} dataKey="students" outerRadius={90} fill="#8884d8"
                   activeIndex={activeIndex} 
                   onMouseEnter={onPieEnter} 
                   activeShape={renderActiveShape} 
                   />
                </PieChart>
                {
                  childResourcePercentage.total_resource_count_percentage !== 0 ? <img src={childImage} alt="student" className="image-c-p" /> : <img src={childImage} alt="student" className="image-c-p  image-c-border" />
                }
              </div>
              <div className="child-profile-content">
                <h3 className="text-capitalize-custome">{firstName ? <span className="text-capitalize">{firstName}&#39;s Learning Pillar Dashboard</span> : <span> Learning Pillar Dashboard</span>}</h3>
                <ul>
                  <li>
                    {" "}
                    <i className="fa fa-circle doot1" aria-hidden="true" />
                    Heart <span className="select-one">(Character)</span>{" "}
                  </li>
                  <li>
                    <i className="fa fa-circle doot2" aria-hidden="true" />
                    Mind<span className="select-one"> (Knowledge)</span>
                  </li>
                  <li>
                    <i className="fa fa-circle doot3" aria-hidden="true" />
                    Soul<span className="select-one"> (Faith)</span>
                  </li>
                  <li>
                    <i className="fa fa-circle doot4" aria-hidden="true" />
                    Strength{" "}
                    <span className="select-one">(Physical Health)</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-sm-3 col-md-3 col-lg-3">
            <div className="chlid-pro-butttnss">
              <button onClick={() => setsharpermision(true)}>
                Share Portfolio
              </button>
              {sharpermision === true ? (
                <ProfileSharepopup
                  datar={() => setsharpermision(false)}
                  userId={userData.userAuth.id}
                  childId={id}
                />
              ) : null}
              <div className="friend-invite">
                <button onClick={() => setReportPopup(!RoportPopup)}>Invite Friend</button>
              </div>
              {RoportPopup === true ? (
                <InviteFriendpopup datar={() => setReportPopup(!RoportPopup)} />
              ) : null}
              <button className="delete" onClick={handleOpen}>
                Delete Profile{" "}
              </button>
              <div className="friend-invite">
                <button onClick={ExportEcelFile}>Export</button>
              </div>
            </div>
          </div>
        </div>
        <div className="handle-heading-text-child">
          <h4 className="text-capitalize-custome">{firstName ? <span className="text-capitalize">{firstName}&#39;s Saved Resources</span> : <span> Saved Resources</span>} </h4>
        </div>
        <div className="searchres-div11 child-filter-page">
          <div className="search-exp-sec-div1">
            <h3>Refine Search Results</h3>
            <div className="saveResourceListgradeRange">
              <div className="form-select">
                <Multiselect
                  options={CategoryOptions}
                  onSelect={(e) => {
                    e?.map((val, i) => setcategory(val.value));
                  }}
                  onRemove={(e) => setcategory('')}
                  displayValue="name"
                  placeholder="Category"
                  showArrow={true}
                  showCheckbox
                  selectedValues={category ? [CategoryOptions.find(item => item.value === category)] : []}
                /></div>
            </div>
            <div className={`childprofilegradeRange childprofilegradeRangeNew ${(gradeLevelData && gradeRange.length > 2) ? "childprofilegradeRangeHeight" : "childprofilegradeRangeWidth"}`}>
              <div className="form-select grademanage">
                <Multiselect
                  options={gradeLevelData}
                  onSelect={handleGrade}
                  onRemove={handleGrade}
                  showCheckbox
                  displayValue="name"
                  placeholder="Grade Range"
                  showArrow={true}
                />
              </div>{" "}
            </div>
            <div className="saveResourceListgradeRange">
              <div className="form-select">
                <Multiselect
                  options={statusData}
                  onSelect={(e) => {
                    e?.map((val, i) => setstatus(val.value));
                  }}
                  onRemove={(e) => setstatus('')}
                  displayValue="name"
                  placeholder="Status"
                  showArrow={true}
                  showCheckbox
                  selectedValues={status ? [statusData.find(item => item.value === status)] : []}
                /></div></div>
            <div className="input-btn-con">
              <input
                type="text"
                placeholder="Keyword Search"
                onChange={(e) => setkeyword(e.target.value)}
              />
            </div>
            <button onClick={handleSearch}>
              <i className="fa fa-search iconsearch" />
            </button>
          </div>
        </div>
        <div className="saveResourceListgradeRange">
          <div className="form-select">
            <Multiselect
              options={viewResult}
              onSelect={(e) => {
                e?.map((val, i) => setlimit(val.value));
              }}
              onRemove={(e) => setlimit('')}
              displayValue="name"
              showArrow={true}
              showCheckbox
              selectedValues={limit ? [viewResult.find(item => parseInt(item.value) === parseInt(limit))] : []}
            />
          </div>
        </div>
        <div className="card-container-handle profileCardContainer">
          <div className="row">
            {resourceData.length > 0 ? (
              resourceData.map((temp, index) => (
                <div className="col-sm-6 col-md-4 col-lg-4" key={index}>
                  <Cardcomponent
                    res_saved_id={temp.id}
                    resource_id={temp.tbl_user_resource.id}
                    res_title={temp.tbl_user_resource.resource_title}
                    res_desc={temp.tbl_user_resource.description}
                    res_fund={temp.tbl_user_resource.public_funding}
                    res_type={temp.tbl_user_resource.resource_type}
                    res_piller={
                      temp.tbl_user_resource.tbl_pillar_resource_mappings
                    }
                    res_rating={temp.tbl_user_resource.rating}
                    res_review={temp.tbl_user_resource.total_reviews}
                    res_viewable={temp.viewable_by_my_friends}
                    res_len={resourcelength}
                    childId={id}
                    getresources={getresources}
                    setPageNum={setPageNum}
                    removeflag="true"
                    save="yes"
                    resource_thumbnail_image={temp.tbl_user_resource?.resource_thumbnail_image}
                    imageLink={temp?.tbl_user_resource?.imageLink}
                  />
                </div>
              ))
            ) : (
              <div>No Data Found</div>
            )}
          </div>
        </div>
        <div className="show-more my-3">
          {remaining && <p className="text-center" onClick={() => setPageNum(pageNum + 1)}>Show more</p>}
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="text-center"
            style={{ color: "#2D3134", fontSize: "30px", lineHeight: "36px" }}>
            Are you sure you want to delete this profile?
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 3 }}
            className="d-flex justify-content-center gap-4">
            <button className="child-delete" onClick={deletechild}>
              Delete
            </button>{" "}
            <button className="child-cancel" onClick={handleClose}>
              Cancel
            </button>
          </Typography>
        </Box>
      </Modal>
      <Modal
        open={open1}
        onClose={handleClose1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="text-center"
            style={{ color: "#2D3134", fontSize: "30px", lineHeight: "36px" }}
          >
            not valid
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 3 }}
            className="d-flex justify-content-center gap-4"
          >
            <button className="child-cancel" onClick={handleClose1}>
              OK
            </button>
          </Typography>
        </Box>
      </Modal>
    </>
  );
}
export default Childprofileupdateview;
