import React, { useRef } from "react";
import { useState, useEffect } from "react";
import './changepass.css';
import { makePostRequest } from "../../services/api";
import { URLS } from "../../constants";
import { toastifyMessage } from "../../utilities/CustomFunctions";
import { useNavigate } from "react-router-dom";
import { AiFillEye } from 'react-icons/ai';
import { AiFillEyeInvisible } from 'react-icons/ai';
import Head from "../Layout.WebAppMate/head/Head";
/**
 * this components use for chnage password
 * @author krishna dobariya <krishna.webappmate@gmail.com>
 * created on: 26-11-2023
 */
function ChangePassword() {
    const passfocus = useRef();
    const newPassfocus = useRef();
    const confirmPassfocus = useRef();
    const [data, setData] = useState({
        oldPassword: "",
        newPassword: "",
        confirmPassword: ""
    })
    const [passicon, setpassicon] = useState({
        oldPassword: false,
        newPassword: false,
        confirmPassword: false
    });
    const [error, setError] = useState({});
    const navigate = useNavigate();
    // Function to handle form submission.
    const ChangePass = (e) => {
        e.preventDefault();
        if (validateForm()) {
            // Form is valid, proceed with the API call.
            makePostRequest(URLS.ChangePassword, null, data, null)
                .then((res) => {
                    console.log("res:::", res);
                    if (res?.code === 200) {
                        toastifyMessage(res?.message, "success");
                        navigate("/");
                    } else {
                        toastifyMessage(res?.message, "error");
                    }
                })
                .catch((error) => {
                    console.log("error:::", error);
                });
        }
    };
    // Function to perform form validation.
    const validateForm = () => {
        const errors = {};
        if (data.oldPassword.trim() === "") {
            errors.oldPassword = "Old Password is required";
        }
        if (data.newPassword.trim() === "") {
            errors.newPassword = "New Password is required";
        } else if (data.newPassword.length < 8 || data.newPassword.length > 20) {
            errors.newPassword = "Please enter a password between 8 and 20 characters long.";
            newPassfocus.current.focus();
        } else if (!/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)/.test(data.newPassword)) {
            errors.newPassword = 'Please include at least one lowercase letter, one uppercase letter, one number, and one special character.';
        }
        if (data.confirmPassword.trim() === "") {
            errors.confirmPassword = "Confirm Password is required";
        } else if (data.confirmPassword !== data.newPassword) {
            errors.confirmPassword = "Passwords do not match";
            confirmPassfocus.current.focus();
        }
        setError(errors);
        if (data.oldPassword.trim() === "") {
            passfocus.current.focus();
        } else if (data.newPassword.trim() === "") {
            newPassfocus.current.focus();
        } else if (data.newPassword.length < 8 || data.newPassword.length > 20) {
            newPassfocus.current.focus();
        } else if (!/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)/.test(data.newPassword)) {
            newPassfocus.current.focus();
        } else if (data.confirmPassword.trim() === "") {
            confirmPassfocus.current.focus();
        }
        return Object.keys(errors).length === 0;
    };

    // this function use for handle input
    const handleInput = (e) => {
        const { name, value } = e.target
        setData({ ...data, [name]: value })
    }
    useEffect(() => {
        document.body.classList.add("ChangePassword");
        return () => {
            document.body.classList.remove("ChangePassword");
        };
    }, []);

    return (<>
        <Head title="Change Password"
            content="MatchED."
            name="Heart Mind Soul Strength" ></Head>
        <div className="col-lg-10 col-md-12 col-sm-12">
            <div className="restPass">
                <form className="frm" onSubmit={ChangePass}>
                    <span className="successfulltext"> </span>
                    <h1>Change Password</h1>
                    <div className="form-group">
                        <input type={passicon?.oldPassword === false ? "password" : "text"} className="form-control" name="oldPassword" placeholder="Old Password"
                            ref={passfocus}
                            onChange={handleInput} />
                        <span className="error">{error.oldPassword}</span>
                        <span className="showhide" onClick={() => setpassicon({
                            ...passicon,
                            oldPassword: !passicon?.oldPassword,

                        })} >
                            {passicon?.oldPassword === true ?
                                <AiFillEye /> : <AiFillEyeInvisible />}
                        </span>
                    </div>
                    <div className="form-group">
                        <input type={passicon?.newPassword === false ? "password" : "text"} className="form-control" name="newPassword" placeholder="New Password" onChange={handleInput}
                            ref={newPassfocus} />
                        <span className="error">{error.newPassword}</span>
                        <span className="showhide" onClick={() => setpassicon({
                            ...passicon,
                            newPassword: !passicon?.newPassword,

                        })} >
                            {passicon?.newPassword === true ?
                                <AiFillEye /> : <AiFillEyeInvisible />}
                        </span>
                    </div>
                    <div className="form-group">
                        <input type={passicon?.confirmPassword === false ? "password" : "text"} className="form-control" name="confirmPassword" placeholder="Confirm Password" onChange={handleInput}
                            ref={confirmPassfocus} />
                        <span className="error">{error.confirmPassword}</span>
                        <span className="showhide" onClick={() => setpassicon({
                            ...passicon,
                            confirmPassword: !passicon?.confirmPassword
                        })} >
                            {passicon?.confirmPassword === true ?
                                <AiFillEye /> : <AiFillEyeInvisible />}
                        </span>
                    </div>
                    <div className="col-lg-12 text-center" tyle={{ textDecoration: "none", color: "white" }}>
                        <button type="submit" className="btn11 btnchangepass"> Change </button>
                    </div>
                </form>
            </div>
        </div>
    </>)
}
export default ChangePassword;