import Logo from "../../assets/Images/Logo.png";
import User from "../../assets/Images/User.png";
import "./loginHeader.css";
import { Link, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { imageUrl, makePostRequestForAll } from "../../services/api";
import { URLS } from "../../constants";
import Notificationmodal from "./Notificationmodal";
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { decodeToken, toastifyMessage, style } from "../../utilities/CustomFunctions";
import { makePostRequest } from "../../services/api";
import FourDotsLoader from "../Common.WebAppMate/FourDoted";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import SingleChat from "../Chat.WebAppMate/SingleChat";
import Chatlist from "../Chat.WebAppMate/Chatlist";
import MyGroup from "./MyGroup";
import { isIOS } from "../../firebase";
import { getChatCount, getNotification, getOpenGlobalChat } from "../../store/reducers/userReducer";

//      * Login header function to display the header according to
//      *  user is login or not
//      *
//      * @author Naresh Kumar <naresh.gulati@webappmate.com>
//      * created on: 10-5-2023
function LoginHeader() {
  const dispatch = useDispatch()
  const cartCount = useSelector((state) => state.cart.value)
  let userStore = useSelector((state) => state.user);
    let getimage = imageUrl;
  let userData = decodeToken();
  const [modal, setmodal] = useState(false);
  const [log, setlog] = useState(false);
  const [getfile, setgetfile] = useState();
  const [spin, setspin] = useState(false);
  const [getname, setgetname] = useState([]);
  const [unreadnotification, setunreadnotification] = useState("");
  const [notification, setnotification] = useState([]);
  const naviget = useNavigate();
  const menuRef = useRef(null);
  const menuBtnRef = useRef(null);
  const menuBtnsRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [userDetail, setUserDetail] = useState("");
  const [showChat, setShowChat] = useState(false);
  const [showGroup, setShowGroup] = useState(false);
  const [count, setCount] = useState(0);
  const [scroll, setScroll] = useState(false);
  const [groupCount, setGroupCount] = useState(false);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setgetname(userData?.userAuth?.firstName)
    setgetfile(getimage + userData?.userAuth?.photo);
  }, [userData.userAuth, getimage])

  console.log("userstoreeeee", userStore)

  const handlelog = () => {
    const popupSmall = document.querySelector("body");
    setlog(!log);
    popupSmall.classList.add("open");
    if (document.body.classList.contains("open")) {
      document.body.classList.add("opens");
    } else {
      document.body.classList.remove("opens");
    }
  };
  // this function is use for logout
  const handlelogout = () => {
    let data = {
      id: userData.userAuth.id,
    };
    makePostRequest(URLS.userLogOut, null, data, null)
      .then((res) => {
        setlog(false);
        if (res.code === 200) {
          setspin(true);
          // Cookies.remove('rememberUser');
          let data = localStorage.getItem("rememberMe") || []
          setTimeout(() => {
            window.location.href = "/sign-in";
            localStorage.clear();
            localStorage.setItem("rememberMe", data)
          }, 3000);
          toastifyMessage(" You have been logged out successfully.", "success");
        } else if (res.code === 400) {
          setspin(false);
          toastifyMessage("User does not exist.", "error");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // this function is use for notifivation
  const shownotification = () => {
    let data = {
      user_id: userData.userAuth.id,
    };
    makePostRequest(URLS.notificationlist, null, data, null)
      .then((res) => {
        if (res?.code === 200) {
          setunreadnotification(res.data.unread_notification);
          setnotification(res.data.notifications);
          dispatch(getNotification({
            notification: res.data.notifications,
            unreadCount: res.data.unread_notification
          }))
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // this function create to get notification
  const notificationCount = () => {
    let data = {
      user_id: userData.userAuth.id,
    };
    makePostRequest(URLS.notificationCount, null, data, null)
      .then((res) => {
        setunreadnotification(res.data.count);
        dispatch(getNotification({
          ...userStore?.notification,
          unreadCount: res.data.count
        }))
      }).catch((err) => {
        console.log(err);
      });
  }

  //  notification permission
  function requestNotificationPermission() {
    if (isIOS()) return
    if (Notification.permission !== "granted") {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          console.log("Notification permission granted!");
        }
      });
    }
  }

  // these functios are use for popup clsoe
  const handleClickOutside = (e) => {
    if (
      menuRef.current &&
      !menuRef.current.contains(e.target) &&
      (!menuBtnRef.current.contains(e.target) || !menuBtnsRef.current.contains(e.target))
    ) {
      setlog(false);
      setmodal(false);
      setShowGroup(false);
    }
  };

  // this function use for get the user chat id
  const AddUser = (id, data) => {
    setUserDetail(data);
    setmodal(false);
    dispatch(getOpenGlobalChat({ ...userStore?.globalOpenChat, chatOpen: false }))
    makePostRequestForAll(URLS.addChatUser, { chat_with: id })
      .then((res) => {
        if (res.code === 200) {
          dispatch(getOpenGlobalChat({ ...userStore?.globalOpenChat, chatOpen: true, userMapId: res?.data?.id, userDetail: userData, senderId: id, group: 0, pageName : "loginheader" , chat_by_block : res?.data?.block,
            block_by_other_person : res?.data?.chat_by_block,
            chat_with_block: res?.data?.chat_with_block,
          }))
        }
      })
      .catch((e) => {
        console.log("error:::::", e)
      });
  };
  // this funcation for chat count
  const getllchatlist = () => {
    makePostRequest(URLS.getChatWithGroupUserSidebar, null, {}, null).then((res) => {
      if (res?.code === 200) {
        dispatch(getChatCount(res?.data.unread_message))
        setCount(res?.data.unread_message);

      }
    });
  };
  useEffect(() => {
    setCount(userStore?.chatCount)
  }, [userStore?.chatCount])

  //this fun update notification count
  const NotificationUpdateCount = () => {
    const data = {
      "notification_id": "122"
    }
    makePostRequest(URLS.NotificationCountUpdate, null, data, null)
      .then((res) => {
        shownotification();
      }).catch((error) => {
        console.log("error::::", error)
      })
  }
  //notification count off function
  const markAllAsRead = () => {
    setmodal(!modal);
    const data = {
      "count": "1"
    }
    makePostRequest(URLS.NotificationCount, null, data, null)
      .then((res) => {
        NotificationUpdateCount();
      }).catch((error) => {
        console.log("error::::", error)
      })

  }

  // this function use for count
  const getGroup = () => {
    const payload = {
      "page_number": 1,
      "item_per_page_group": 10
    }
    makePostRequest(URLS.getMyGroupSidebar, null, payload, null)
      .then((res) => {
        console.log("res", res)
        if (res?.data?.myGroups?.length > 0) {
          setGroupCount(true)
        } else {
          setGroupCount(false)
        }
      }).catch((error) => {
        console.log("error:::", error)
      })
  }

  // these useEffect use for fetch the data
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside, true);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside, true);
    };
  }, []);
  useEffect(() => {
    setunreadnotification(userStore?.notification?.unreadCount);
    setnotification(userStore?.notification?.notification);
  }, [userStore?.notification])

  useEffect(() => {
    userStore?.userData?.name && setgetname(userStore?.userData.name);
    userStore?.userData?.image && setgetfile(getimage + userStore?.userData.image);
    // eslint-disable-next-line
  }, [userStore?.userData])

  useEffect(() => {
    setIsMounted(true);
    return () => {
      setIsMounted(false);
    };
  }, []);

  useEffect(() => {
    if (isMounted) {
      setTimeout(() => {
        requestNotificationPermission()
        notificationCount();
        getGroup()
        getllchatlist()
      }, [1000])
    }
    // eslint-disable-next-line
  }, [isMounted])
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 20);

    });
    if (scroll === false) {
      document.body.classList.add("bobycontentcroll");
      document.body.classList.remove("bobycontentcrollFix");
    }
    else if (scroll === true) {
      document.body.classList.add("bobycontentcrollFix");
      document.body.classList.remove("bobycontentcroll");
    }

  }, [scroll]);

  // ---------------------UI start
  return (
    <>
      {spin && (
        <div className="sharepopupOuter backgroundOpacity1">
          <FourDotsLoader />
        </div>
      )}
      <div className={`header1 ${scroll ? "headerfix" : "headerscroll"}`} onSubmit={(event) => event.preventDefault()}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-sm-4 col-md-4 col-lg-3">
              <Link to="/" className="Imglogo">
                <img className="img-fluid" src={Logo} alt="matched user" />
              </Link>
            </div>
            <div className="col-sm-12 col-md-5 col-lg-3 hidden-sm hidden-xs">
            </div>
            <div className="col-12 col-sm-8 col-md-8 col-lg-6">
              <div className="header-center">
                <div className="left-div">
                  {(localStorage.getItem("cart") || localStorage.getItem("signupcard")) && parseInt(cartCount) !== 0 && <div onClick={() => naviget("/purchase-page-card/no")} className="c-pointer well-icon" >
                    <span><i className="fa fa-shopping-cart" /><span className="noti">{cartCount}</span></span></div>}
                  {groupCount && <div className="well-icon group-icon">
                    <span className="wells-icon" onClick={() => setShowGroup(!showGroup)}><i className='fa fa-group'></i></span>
                  </div>}
                  <div className="well-icon well-chat-icon">
                    <span className="wells-icon" onClick={() => {    dispatch(getOpenGlobalChat({ ...userStore?.globalOpenChat, chatOpen: false })); setShowChat(!showChat) }}> {/* <ChatIcon /> */} <i className='fa fa-comments'></i>{count > 0 && <span className="noti">{count}</span>}</span>
                  </div>
                  {showChat === true ? (
                    <Chatlist datar={() => setShowChat(!showChat)} value={userData.userAuth.id} count={((data) => setCount(data))} forwardedref={menuRef} />
                  ) : null}
                  <div
                    onClick={() => { shownotification(); markAllAsRead() }}
                    className="well-icon"
                    ref={menuBtnsRef}
                  >
                    {" "}
                    <span>
                      <i className="fa fa-bell" />
                      {
                        parseInt(unreadnotification) !== 0 &&
                        <span className="noti"> {unreadnotification}</span>}
                    </span>
                  </div>
                  {modal === true ? (
                    <Notificationmodal
                      setmodal={setmodal}
                      forwardedref={menuRef}
                      datar={() => setmodal(!modal)}
                      notice={notification}
                      userid={userData.userAuth.id}
                      funCall={shownotification}
                      add={AddUser}

                    />
                  ) : null}
                  {showGroup === true ? (
                    <MyGroup
                      datar={() => setShowGroup(false)} forwardedref={menuRef}
                    />
                  ) : null}

                  {getname ? (
                    <h3 className="jane-deo"> {getname}</h3>
                  ) : (
                    <h3 className="jane-deo">Guest</h3>
                  )}
                  <div
                    className="accountBox"
                    ref={menuBtnRef}
                    onClick={handlelog}
                  >
                    {getfile ? (
                      <img src={getfile} className="user-img" alt="matched User" />
                    ) : (
                      <img src={User} className="user-img" alt="matched User"></img>
                    )}
                  </div>
                </div>
              </div>
              <div className="login-helpcenter-header">
                {/* <h4><a href="https://matchedcommunity.zendesk.com/hc/en-us" target="_blank">Help Center</a></h4> */}
              </div>
              {log === false ? null : (
                <div className="dropdown-link" ref={menuRef}>
                  <ul>
                    <li onClick={() => { setlog(false); naviget("/parent-registration-form") }}>
                      <Link to="">
                        <i className="fa fa-user" aria-hidden="true"></i>{" "}
                        &nbsp;My Profile
                      </Link>
                    </li>
                    <li onClick={() => { setlog(false); naviget("/change-password") }}>
                      <Link to="">
                        <i className="fa fa-key" aria-hidden="true"></i>{" "}
                        &nbsp;Change Password
                      </Link>
                    </li>
                    <li onClick={() => { setlog(false); naviget("/my-subscriptions") }}>
                      <Link to="">
                        <i className="fa fa-list" aria-hidden="true"></i>{" "}
                        &nbsp;My Subscriptions
                      </Link>
                    </li>
                    {/* <li onClick={()=>{setlog(false);naviget("/ticket-list")}}>
                      <Link to="">
                        <i className="fa fa-ticket" aria-hidden="true"></i>{" "}
                        &nbsp;My Tickets
                      </Link>
                    </li> */}
                    <li className="down-top" onClick={handlelogout}>
                      <Link to="">
                        <i className="fa fa-sign-out" aria-hidden="true"></i>{" "}
                        LogOut
                      </Link>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="text-center"
            style={{ color: "#2D3134", fontSize: "30px", lineHeight: "36px" }}
          >
            Your search limit crossed. Do you want to continue?
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 3 }}
            className="d-flex justify-content-center gap-4"
          >
            <button
              className="child-delete"
              onClick={() => naviget("/subscription")}
            >
              Continue
            </button>{" "}
          </Typography>
        </Box>
      </Modal>
      {
        userStore?.globalOpenChat?.chatOpen &&  userStore?.globalOpenChat?.pageName === "loginheader" &&
        <SingleChat groupName={userDetail} chatClose={() => 
          dispatch(getOpenGlobalChat({
            chatOpen: false, userDetail: "", userMapId: "", senderId: "", group:"", pageName : ""
             }))

        } userMapId={userStore?.globalOpenChat?.userMapId} group={userStore?.globalOpenChat?.group} senderId={userStore?.globalOpenChat?.senderId} getAllUser={() => { }}
          statuspage={"loginheader"}
        ></SingleChat>
      }
    </>
  );
}
export default LoginHeader;
