import React, { useEffect, useState } from 'react'
import { Month, Week, Day, ScheduleComponent, ViewsDirective, ViewDirective, Inject } from '@syncfusion/ej2-react-schedule';
import '@syncfusion/ej2-base/styles/material.css';
import '@syncfusion/ej2-react-schedule/styles/material.css';
import { toastifyMessage, style } from '../../utilities/CustomFunctions';
import { imageUrl, makePostRequest, makePostRequestForAll } from '../../services/api';
import { URLS } from '../../constants';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation} from 'react-router-dom';
import { Box, Modal, Typography } from '@mui/material';
import { CalendarComponent } from "@syncfusion/ej2-react-calendars";
import FourDotsLoader from '../Common.WebAppMate/FourDoted';
import SingleChat from '../Chat.WebAppMate/SingleChat';
import Multiselect from 'multiselect-react-dropdown';
import { getOpenGlobalChat } from '../../store/reducers/userReducer';

const SessionHistory = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  let userStore = useSelector((state) => state.user);
  const location = useLocation();
  const [data1, setData1] = useState([])
  const [currentView, setCurrentView] = useState('Day');
  const [curremtDate, setCurremtDate] = useState(new Date())
  const [childData, setChildData] = useState([]);
  const [selectedChild, setSelectedChild] = useState("");
  const [resourceId, setResourceId] = useState("")
  const [open, setOpen] = useState(false)
  const [reOpen, setReOpen] = useState(false)
  const [cancleSubId, setCancleSubId] = useState("")
  const [dateWise, setDateWise] = useState(new Date())
  const [minDate, setMinDate] = useState(null)
  const [StartTime, setStartTime] = useState("")
  const [EndTime, setEndTime] = useState("")
  const [dateList, SetdateList] = useState({})
  const [scheduleId, setScheduleId] = useState("")
  const [isInnerLoader, setIsInnerLoader] = useState(false)
  const [modalData, setModalData] = useState({})
  const [spin, setSpin] = useState(false)
  const [masterData, setGetMSMasterData] = useState({});
  const [show, setShow] = useState(false)
  const [resourceData, setResourceData] = useState([]);
  const [isMounted, setIsMounted] = useState(false);
  const eventSettings = { dataSource: data1 }
  let uimage = imageUrl;
  const onDateSelect = async (date, id) => {
    setIsInnerLoader(true)
    let data =
    {
      "scheduleId": id ?? scheduleId,
      "schedule_date": moment(date).format("YYYY-MM-DD") ?? moment(dateWise).format("YYYY-MM-DD"),
    }
    await makePostRequest(URLS.getSchedualManualDate, null, data, null)
      .then((res) => {
        SetdateList(res?.data)
        setIsInnerLoader(false)

      })
      .catch((error) => {
        setIsInnerLoader(false);
        console.log(error);
      });
  }
  useEffect(() => {
    onDateSelect(dateWise)
    // eslint-disable-next-line
  }, [dateWise])
  useEffect (() => {
    if (location?.state?.status === "mentoring_booking_session"){
      setCurremtDate(new Date(location?.state?.create_date?.split('T')[0]))
      setResourceId(location?.state?.res_id)
      setSelectedChild(location?.state?.child_id)
    }
      }, [location])

  useEffect(() => {
    setIsMounted(true);
    return () => {
      setIsMounted(false);
    };
  }, []);

  // this function use for get child
  const getUserChilds = () => {
    setSpin(true)
    let data = {
      resource_id: "",
    };
    makePostRequest(URLS.getChildList, null, data, null)
      .then((res) => {
        setSpin(false)
        if (res.code === 200) {
          // res.data.length > 0 &&
          //  setSelectedChild(res?.data?.[0]?.id)
          const arr = []
          res?.data?.length > 0 && res?.data.forEach((val) => {
            if (val.childOwner === 1) {
              return arr.push({ name: val.firstName + " " + val?.lastName, value: val?.id })
            }
          })
          setChildData(arr);
        }
      })
      .catch((error) => {
        setSpin(false)
        console.log(error);
      });
  };

  const onNavigating = (args) => {
    setCurremtDate(args?.currentDate)
    if (args?.currentView) {
      setCurrentView(args?.currentView);
    } else {
      setCurremtDate(args?.currentDate)
    }
  };

  function applyCategoryColor(args, currentView) {
    var categoryColor = args.data.categoryColor;
    if (!args.element || !categoryColor) {
      return;
    }
    if (currentView === 'Agenda') {
      args.element.firstChild.style.borderLeftColor = categoryColor;
    }
    else {
      args.element.style.backgroundColor = categoryColor;
    }
  }
  const onEventRendered = (args) => {
    applyCategoryColor(args, currentView);
  }
  // useEffect(() => {
  //   // This effect runs whenever location (URL) changes so chat window not open again when you come back on this page again
  //   dispatch(getOpenGlobalChat(
  //     {chatOpen : false, userDetail : "", userMapId: "", senderId: "", group: "", pageName : "" })) // Reset or clear the userstore data here
  // }, [location.pathname]);
  // this function use for het date session data
  const getData = () => {
    function getCurrentWeekDates() {
      const today = curremtDate;
      const dayOfWeek = today.getDay(); // 0 (Sunday) to 6 (Saturday)
      const firstDay = new Date(today);
      firstDay.setDate(today.getDate() - dayOfWeek); // Subtract day of the week
      const lastDay = new Date(today);
      lastDay.setDate(today.getDate() + (6 - dayOfWeek)); // Add to reach end of week
      return { firstDay, lastDay };
    }
    const { firstDay, lastDay } = getCurrentWeekDates();
    const currentMonth = curremtDate.getMonth();
    const currentYear = curremtDate.getFullYear();
    const firstDayOfMonth = new Date(currentYear, currentMonth, 1);
    const lastDayOfMonth = new Date(currentYear, currentMonth + 1, 0);
    const formattedFirstDay = `${firstDayOfMonth.getFullYear()}-${(firstDayOfMonth.getMonth() + 1).toString().padStart(2, '0')}-${firstDayOfMonth.getDate().toString().padStart(2, '0')}`;
    const formattedLastDay = `${lastDayOfMonth.getFullYear()}-${(lastDayOfMonth.getMonth() + 1).toString().padStart(2, '0')}-${lastDayOfMonth.getDate().toString().padStart(2, '0')}`;
    setSpin(true)
    let body = {
      "child_id": selectedChild || 0,
      "start_date": currentView === "Day" ? moment(curremtDate).format("YYYY-MM-DD") :
        currentView === "Week" ? moment(firstDay).format("YYYY-MM-DD") :
          currentView === "Month" ? formattedFirstDay : moment(new Date()).format("YYYY-MM-DD"),
      "end_date": currentView === "Day" ? moment(curremtDate).format("YYYY-MM-DD") :
        currentView === "Week" ? moment(lastDay).format("YYYY-MM-DD") : currentView === "Month" ? formattedLastDay : moment(new Date()).format("YYYY-MM-DD"),
      "resource_id": resourceId,
      "schedule_type": ""
    }
    console.log("body::", body)
    makePostRequest(URLS.getMSDataUser, null, body, null)
      .then((response) => {
        setSpin(false)
        let arr = []
        response?.data?.event?.map(v => (
          v?.tbl_user_mentoring_session_datetimes?.map(k => (arr.push({
            child_id : v?.child_id,
            Id: v?.id,
            Subject: k?.booking_status === "Cancelled" ? "Cancelled" : v?.sold_by_user?.firstName + " " + v?.sold_by_user?.lastName,
            StartTime: moment(k?.session_date + "T" + k?.session_time).format('YYYY-MM-DDTHH:mm:ssZ'),
            EndTime: moment(k?.session_date + "T" + k?.session_end_time).format('YYYY-MM-DDTHH:mm:ssZ'),
            date: moment(k?.session_date).format("MM-DD-YYYY"),
            resurceName: v?.tbl_user_resource?.resource_title,
            IsReadOnly: true,
            categoryColor: k ? (k.booking_status === "Cancelled") ? "#F19357" : "#265472" : "#265472",
            key: "avlbl",
            RoomId: v?.resource_id,
            parentName: "sss",
            childName: v?.tbl_child?.firstName + " " + v?.tbl_child?.lastName,
            amount: v?.price_per_slot?.toFixed(2) > 0 ? "$" + v?.price_per_slot?.toFixed(2) : "",
            status: k?.status,
            refNumber: v?.tbl_payment_history?.payment_reference_no,
            session_datetime_id: k?.id,
            scheduleId: v?.scheduleId,
            mentorName: v?.sold_by_user?.firstName + " " + v?.sold_by_user?.lastName,
            isCanceled: k?.is_cancelled === "no" ? false : true,
            bookingStatus: (k.booking_status === "Cancelled") ? "Cancelled" : v?.price_per_slot?.toFixed(2) > 0 ? k?.booking_status : "successful",
            chat: v?.sold_by_user
          })))
        ))
        setData1(arr)
      }).catch(e => setSpin(false))
  }
  // this function use for get resource
  const getResource = () => {
    makePostRequest(URLS.buyerResource, null, {}, null)
      .then((res) => {
        const arr = [{ value: "", label: "All" }]
        res.data.forEach((val) => {
          arr.push({ value: val.tbl_user_resource.id, label: val.tbl_user_resource.resource_title })
        })
        setResourceData(arr)
      }).catch((error) => {
        console.log("error", error)
      })
  }
  useEffect(() => {
    if (isMounted) {
      getData()
    }
    // eslint-disable-next-line
  }, [currentView, selectedChild, curremtDate, resourceId, isMounted])

  //handle on click popup open 
  const onPopupOpen = (args) => {
    if (args?.data?.IsReadOnly === true) {
      args.cancel = false
    } else if (args?.type === "EventContainer") {
      args.cancel = false;
    } else {
      args.cancel = true;
    }
  }
  // this function use for chat
  const AddUser = (id, userData) => {
    dispatch(getOpenGlobalChat({ ...userStore?.globalOpenChat, chatOpen: false }))
    makePostRequestForAll(URLS.addChatUser, { chat_with: id })
      .then((res) => {
        if (res.code === 200) {
          dispatch(getOpenGlobalChat({ ...userStore?.globalOpenChat, chatOpen: true, userMapId: res?.data?.id, userDetail: userData, senderId: id, group: 0, pageName: "session",
            chat_by_block : res?.data?.block, block_by_other_person : res?.data?.chat_by_block,
            chat_with_block: res?.data?.chat_with_block,
           }))
        }
      })
      .catch((e) => {
        console.log("error:::::", e)
      });
  };

  // syncfusion popup header
  const headerTemplate = (props) => {
    return (<div className="quick-info-header">
      <div className="quick-info-header-content" style={{ alignItems: 'left', color: '#919191', marginLeft: "-15px" }}>
        <div className="quick-info-title">{props?.resurceName}</div>
        <div className="quick-info-title" >Vendor: {props?.mentorName} </div>
        <div className="quick-info-title" >student : {props?.childName} </div>
        <div className="quick-info-title">{props?.date + " (" + moment(props?.StartTime).format("hh:mm A") + " - " + moment(props?.EndTime).format("hh:mm A") + ")"}</div>
      </div>
    </div>);
  }
  // syncfusion popup content
  const contentTemplate = (props) => {
    return (<div className="quick-info-content">
      <div className="event-content">
        <div className=" meeting-subject-wrap">
          <label>Ref. Number :</label>
          <span>{props?.refNumber}</span>
        </div>
        {
          props?.amount && <div className="meeting-subject-wrap">
            <label>Amount :</label>
            <span>{props?.amount}</span>
          </div>
        }
        {
          props?.status === "Partial" ? <div className="notes-wrap">
            <label>Payment Status :</label>
            <span>Partial Refund</span>
          </div> : <> {props?.status === "full_refund" ?
            <div className="notes-wrap">
              <label>Payment Status :</label>
              <span>Full Refund</span>
            </div>
            : <>{
              props?.amount ? <div className="notes-wrap">
                <label>Payment Status :</label>
                <span>{props?.status}</span>
              </div> : ""
            }</>
          }
          </>
        }
        <div className="notes-wrap">
          <label>Booking Status :</label>
          <span>{props?.bookingStatus}</span>
        </div>
      </div>
    </div>);
  }
  // syncfusion popup footer
  const footerTemplate = (props) => {
    return (<div className="quick-info-footer">
      <div className="cell-footer" style={{ paddingBottom: "-23px" }}>
        <div className="cancelbuttonflex">
        {props?.isCanceled === false && <> <button className="contiundeflexbutton c-pointer" style={{ marginRight: "12px" }}
             onClick={() => {
              AddUser(props?.chat?.id, { "name": props?.chat?.firstName + " " + props?.chat?.lastName, "image": uimage + props?.chat?.photo })
              window.scrollTo({ top: 1400, behavior: 'smooth' });
            }}>
              Chat
              
          </button>
            {(props?.isCanceled === false && props?.bookingStatus !== "Completed") ? <button className="cancelflexbutton c-pointer" onClick={() => { setOpen(true); setCancleSubId(props?.session_datetime_id) }}> Cancel Booking</button>
              : ""}
            {(props?.isCanceled === false && props?.bookingStatus !== "Completed") && <button className="contiundeflexbutton c-pointer" onClick={() => {
              setReOpen(true); setSelectedChild(props?.child_id); setCancleSubId(props?.session_datetime_id); setDateWise(props?.StartTime); setScheduleId(props?.scheduleId); onDateSelect(props?.StartTime, props?.scheduleId); setModalData({
                mentor: props?.mentorName, dateTime: props?.date + " (" + moment(props?.StartTime).format("hh:mm A") + " - " + moment(props?.EndTime).format("hh:mm A") + ")", resurceName: props?.resurceName
              })
            }}> Reschedule </button>}</>} 
        </div>
      </div>
    </div>);
  }
  const quickInfoTemplates = {
    header: headerTemplate,
    content: contentTemplate,
    footer: footerTemplate
  };
  // this function use for cancel session
  const CancleSub = async () => {
    setSpin(true)
    const body = {
      "session_datetime_id": cancleSubId,
      "child_id": selectedChild
    }
    await makePostRequest(URLS.cancelMSDateTime, null, body, null)
      .then((response) => {
        setSpin(false)
        toastifyMessage(response?.message, "success");
        setOpen(false)
        getData()
      }).catch(e => {
        console.log("e", e)
      })
  }
  // this function use for reschedule session
  const reschedualSession = async () => {
    setSpin(true)
    const body = {
      "session_datetime_id": cancleSubId,
      "session_date": moment(dateWise).format("YYYY-MM-DD"),
      session_end_time: EndTime,
      "session_time": StartTime,
      "child_id": selectedChild
    }
    await makePostRequest(URLS.rescheduleMSDateTime, null, body, null)
      .then((response) => {
        console.log("res::::", response)
        if (response?.code === 200) {
        setSpin(false)
        toastifyMessage(response?.message, "success");
        setCurremtDate(dateWise)
        setReOpen(false)
        setStartTime("")
        setEndTime("")
        setCancleSubId("")
        getData()
        }else{
          toastifyMessage(response?.message, "error");
        }
      }).catch(e => {
        console.log("e", e)
      })
  }

  // this function is use for user select the date
  const onUserSelect = (start, aa, end) => {
    setStartTime(start)
    setEndTime(end)
    let dummy = { ...dateList }
    console.log("dummy", dummy, start)
    let data = dummy?.sessions_timing?.map(v => (v?.[aa] === start) ? { ...v, isSelected: true } : { ...v, isSelected: false })
    SetdateList({ ...dummy, sessions_timing: data })
  }
  useEffect(() => {
    getUserChilds();
    getResource()
    document.body.classList.add("sessionhistory");
    return () => {
      document.body.classList.remove("sessionhistory");
    };
  }, []);

  // get get schedule date by id
  useEffect(() => {
    const getDataByid = () => {
      setIsInnerLoader(true)
      setShow(false)
      makePostRequest(
        URLS.getMSMasterData, null, { scheduleId: scheduleId, },
        null
      ).then((res) => {
        if (res.code === 200) {
          setShow(true)
          setIsInnerLoader(false)
          setGetMSMasterData(res?.data);
        }
      })
        .catch((error) => {
          setIsInnerLoader(false)
          console.log(error);
        });
    };
    scheduleId && getDataByid();
  }, [scheduleId])

  // this function use for disable date 
  const disabledDate = (args) => {
    let date = masterData?.scheduleData?.[0]?.scheduleDates?.map(
      (v) => v?.schedule_date
    );
    if (!date?.includes(moment(args?.date).format("YYYY-MM-DD"))) {
      args.isDisabled = true;
    }
    if (!minDate) setMinDate(date?.length > 0 ? new Date(date[0]) : null)
    if (!dateWise) setDateWise(date?.length > 0 ? new Date(date[0]) : null)
  };
  return (
    <>
      <div className="col-lg-10 mb-5">
        {
          spin && <div className="sharepopupOuter backgroundOpacity1">
            <FourDotsLoader />
          </div>
        }
        <div>
          {
            userStore?.globalOpenChat?.chatOpen && userStore?.globalOpenChat?.pageName === "session" &&
            <SingleChat groupName={userStore?.globalOpenChat?.userDetail} chatClose={() =>    dispatch(getOpenGlobalChat({
              chatOpen: false, userDetail: "", userMapId: "", senderId: "", group: "", pageName: ""
               }))} userMapId={userStore?.globalOpenChat?.userMapId} senderId={userStore?.globalOpenChat?.senderId} group={userStore?.globalOpenChat?.group} statuspage={"session"} getAllUser={() => { }} />
          }
        </div>
        <div className="tobarbackcontee">
          <div className="gobackcontainer">
            <h5 className="" onClick={() => navigate(-1)}><span><i className="fa fa-angle-left" aria-hidden="true"></i></span> Go Back</h5>
            <div className="tobarbackcontee allchildSelect">
              <div className="gobackcontainer">
                {
                  childData.length > 0 && <div className="saveResourceChildSelect w-100">
                    <div className="bookasessionSelect">
                      <Multiselect
                        options={childData}
                        onSelect={(selectedList) => {
                          selectedList?.forEach((val, i) => {
                            setSelectedChild(val.value);
                          });
                        }}
                        onRemove={(selectedList) => {
                          selectedList?.forEach((val, i) => {
                            setSelectedChild(val.value);
                          });
                        }}
                        selectedValues={selectedChild ? [childData.find(item => item.value === selectedChild)] : []}
                        showCheckbox
                        displayValue="name"
                        placeholder="Select Student"
                        showArrow={true}
                      />
                    </div>
                  </div>
                }
                {
                  resourceData.length > 0 && <div className="saveResourceChildSelect w-100">
                    <div className="bookasessionSelect sessionBookedhis">
                      <Multiselect
                        options={resourceData}
                        onSelect={(selectedList) => {
                          selectedList?.forEach((val, i) => {
                            setResourceId(val.value);
                          });
                        }}
                        onRemove={(selectedList) => {
                          selectedList?.forEach((val, i) => {
                            setResourceId(val.value);
                          });
                        }}
                        selectedValues={resourceId ? [resourceData.find(item => item.value === resourceId)] : [{value: '', label: 'All'}]}
                        showCheckbox
                        displayValue="label"
                        placeholder="Select resource"
                        showArrow={true}
                      />
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
        <div className='bookmoniterdiv'>
          <h2>Sessions Booked</h2>
          <ScheduleComponent
            width='97%' height='435px' currentView='Month' selectedDate={curremtDate}
            cssClass='quick-info-template'
            eventSettings={eventSettings}
            // group={group}
            eventRendered={onEventRendered}
            navigating={onNavigating}
            popupOpen={onPopupOpen}
            disabled={true}
            readOnly={true}
            timeFormat='hh:mm a'
            quickInfoTemplates={quickInfoTemplates}
          >
            <ViewsDirective>
              <ViewDirective option='Day' />
              <ViewDirective option='Week' />
              <ViewDirective option='Month' />
            </ViewsDirective>
            <Inject services={[Day, Week, Month]} />
          </ScheduleComponent>
        </div>
      </div>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="text-center"
            style={{ color: "#2D3134", fontSize: "20px", marginTop: "20px", lineHeight: "36px" }}
          >
            Are you sure you want to cancel this booking?
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 3 }}
            className="d-flex justify-content-center gap-4"
          >
            <button className="child-save" type="button" onClick={() => CancleSub(cancleSubId)}>
              Yes
            </button>{" "}
            <button className="child-delete" onClick={() => setOpen(false)}>
              No
            </button>{" "}

            {/* </div> */}
          </Typography>
        </Box>
      </Modal>
      <Modal
        open={reOpen}
        onClose={() => setReOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className='modal-cont'>
          <Box sx={style}>
            <div id="modal-modal-title" className="text-center" style={{ color: "rgb(45, 49, 52)", fontSize: " 20px", lineHeight: "36px" }}>
              <div className="reschedulepopup">
                <h3 className='resnamesec'>{modalData?.resurceName}</h3>
                <h3>{modalData?.mentor} </h3>
                <h3>{modalData?.dateTime} </h3>
              </div>

              <div className="scrollmobile">
                <div className="row p-3">
                  <div className="col-lg-6 col-sm-6 col-12">
                    {
                      show && <CalendarComponent style={{ float: "left" }}
                        id="calendar"
                        onChange={(e) => setDateWise(e.value)}
                        value={dateWise} min={new Date()} renderDayCell={disabledDate} />
                    }
                  </div>
                  <div className="col-lg-6 col-sm-6 col-12">
                    <div className="picktimecalendaer" style={{ display: "flex", flexWrap: "wrap" }}>
                      <div className="picktimeRightSide">
                        {isInnerLoader ? <ul>
                          <li>
                            <div className="">
                              <FourDotsLoader />
                            </div>
                          </li>
                        </ul> : <ul>
                          {dateList?.sessions_timing?.length > 0 ? dateList?.sessions_timing?.map(v => (
                            <>
                              <li> <button referrerPolicy="no-referrer" className={`${v?.status === "notavailable" && "notavailable"} ${v?.isSelected && "selectedTime"} c-pointer`} onClick={() => onUserSelect(v?.start, "start", v?.end)}><span>{moment(
                                v?.start,
                                "h:mm:ss A"
                              ).format("hh:mm A")}</span></button></li>
                            </>
                          )) : <h1>No slots are available on this date , Please check availability on other dates</h1>}
                        </ul>}
                      </div>
                      <div className="d-flex justify-content-center gap-4 mt-3">
                        <button className="child-delete" onClick={() =>{ setReOpen(false); setSpin(false)}}>
                          Cancel
                        </button>{" "}
                        <button className="child-save" type="button" onClick={() => reschedualSession()}>
                          Save
                        </button>{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Box>
        </div>
      </Modal>
    </>
  )
}
export default SessionHistory