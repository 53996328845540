import "../Common.WebAppMate/threedotspopup.css";
import React from "react";
import { makePostRequest } from "../../services/api";
import { URLS } from "../../constants";
import { toastifyMessage } from "../../utilities/CustomFunctions";
import { Link } from "react-router-dom";

// /*
//      * Notification function is used to do view all the notification
//      *
//      * @author Upendra Kumar <upendra.webappmate@gmail.com>
//      * created on: 07-07-2023
//      * api implimentation by @author krishna dobariya  <krishna.webappmate@gmail.com>
//      * created on 10-07-2023
//      * /

function NotificationThreedotspopup(props) {

  //this function used for reading all notification
  const markAllAsRead = () => {
    makePostRequest(URLS.markallnoticeread, null, {}, null)
      .then((res) => {
        props?.funCall()
        toastifyMessage(res.message, "success")
      }).catch((error) => {
        console.log("error::::", error)
      })
  }

  return (
    <>
      <div className="dotspopOuter">
        <div className="dotspop" ref={props?.forwardedref}>
          <Link to="#" onClick={markAllAsRead} className="border-bottom" >
            <i className="fa fa-check"></i> Mark all as read
          </Link>
          <Link to="/notification-settings" className="border-bottom"><i className="fa fa-cog"></i> Notification Settings</Link>
          <Link to="/notification" className="border-bottom"><i className="fa fa-desktop"></i> Open Notifications</Link>
        </div>
      </div>
    </>
  );
}
export default NotificationThreedotspopup;
