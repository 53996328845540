import React, { useEffect, useRef, useState, } from "react";
import "./BookASessionManualpicking.css";
import Select from "react-select";
import { useParams } from "react-router";
import { URLS } from "../../constants";
import { makePostRequest } from "../../services/api";
import { CalendarComponent, DatePickerComponent, } from "@syncfusion/ej2-react-calendars";
import moment from "moment";
import { decodeToken, toastifyMessage, style } from "../../utilities/CustomFunctions";
import { Link, useNavigate } from "react-router-dom";
import FourDotsLoader from "../Common.WebAppMate/FourDoted";
import { useDispatch } from "react-redux";
import { incrementByAmount } from "../../store/reducers/cartReducer";
import { Box, Modal, Typography } from "@mui/material";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import CryptoJS from "crypto-js";
const secretKey = process.env.REACT_APP_DATA_ENCRYPT_DESCRYP_KEY;
function BookASessionManualpicking() {
  const dispatch = useDispatch();
  const [gradeLevelIds, setGradeLevelIds] = useState([]);
  const [gradeLevelData, setGradeLevelData] = useState([]);
  const [masterData, setGetMSMasterData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [dateWise, setDateWise] = useState("");
  const [Day, setDay] = useState("");
  const [frequency, setFrequency] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setendTime] = useState("");
  const [dateList, SetdateList] = useState({});
  const [dataList, setDataList] = useState([]);
  const [dataList2, setDataList2] = useState([]);
  const [isExtraEdit] = useState(false);
  const [cart, setCart] = useState({});
  const [otherData, setOtherData] = useState([]);
  const [editIndex, setEditIndex] = useState(null);
  const navigate = useNavigate();
  const payLoad = decodeToken().userAuth?.timezone;
  const [tabNumber, setTabNumber] = useState(0)
  const [minDate, setMinDate] = useState(null)
  const [open, setOpen] = useState(false);
  const [removeDate, setRemoveDate] = useState("");
  const [newDataList, setNewDataList] = useState([]);
  const [newSelectedTime, setNewSelectedTime] = useState([]);
  const [isInnerLoader, setIsInnerLoader] = useState(false);
  const [isInnerLoader2, setIsInnerLoader2] = useState(false);
  const datepickerRef = useRef(null);
  const datepickerRef2 = useRef(null);
  const params = useParams();
  const [type, setType] = useState("yes");

  const chatContainerRef = useRef(null);
  const handleInputClick = () => {
    if (datepickerRef.current) {
      datepickerRef.current.show(null, 10, 0);
    }
  };
  const handleInputClick2 = () => {
    if (datepickerRef2.current) {
      datepickerRef2.current.show(null, 0, 0);
    }
  };
  useEffect(() => {
    if (params.type) {
      params.type === "yes" ? setType(true) : setType(false)
    }
  }, [params.type])

  // this function is use for scrolll bottom
  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTo(0, chatContainerRef.current.scrollHeight);
    }
  };
  useEffect(() => {
    scrollToBottom();
  }, [dateList])

  useEffect(() => {
    if (localStorage.getItem(type ? "signupcard" : "cart")) {
      setCart(JSON.parse(localStorage.getItem(type ? "signupcard" : "cart")));
    } else {
      let data = { scheduleData: [], save_type: "confirm" };
      setCart(data);
      localStorage.setItem(type ? "signupcard" : "cart", JSON.stringify(data));
    }
    if (localStorage.getItem(type ? "signupData" : "otherData")) {
      setOtherData(JSON.parse(localStorage.getItem(type ? "signupData" : "otherData")));
    } else {
      let data = [];
      setOtherData(data);
      localStorage.setItem(type ? "signupData" : "otherData", JSON.stringify(data));
    }
    // eslint-disable-next-line
  }, [localStorage, type]);

  // start ----------this function use for get child
  const getUserChilds = () => {
    setIsLoading(true);
    let data = {
      resource_id: "",
    };
    makePostRequest(URLS.getChildList, null, data, null)
      .then((res) => {
        if (res.code === 200) {
          let data = res?.data;
          let showData = data?.filter((v) => v?.childOwner === 1);
          let options = [];
          showData?.length > 0 &&
            showData?.forEach((val, i) => {
              options.push({
                value: val.id,
                label: val.firstName + " " + val.lastName,
              });
            });
          setGradeLevelData(options);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };
  // ----------------end

  // this function use for gey data bye id
  const getDataByid = () => {
    setIsLoading(true);
    makePostRequest(
      URLS.getMSMasterData,
      null,
      {
        scheduleId: params?.id,
      },
      null
    )
      .then((res) => {
        if (res.code === 200) {
          setGetMSMasterData(res?.data);
        }
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };
  // this function use for date selcte
  const onDateSelect = async (e, edittime, isUp) => {
    setIsInnerLoader(true);
    if (e?.value) {
      setDateWise(e?.value);
    }
    let data = {
      scheduleId: params?.id,
      schedule_date: edittime
        ? moment(edittime?.d).format("YYYY-MM-DD")
        : e?.value
          ? moment(e.value).format("YYYY-MM-DD")
          : moment(dateWise).format("YYYY-MM-DD"),
    };
    await makePostRequest(URLS.getSchedualManualDate, null, data, null)
      .then((res) => {
        SetdateList(res?.data);
        if (edittime) {
          onUserSelect(
            edittime?.a,
            edittime?.b,
            edittime?.c,
            edittime?.d,
            edittime?.e,
            res?.data,
            false,
            isUp
          );
        }
        setIsLoading(false);
        setIsInnerLoader(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        setIsInnerLoader(false);
      });
  };
  // get shedual date 
  const getSchedualAutoReoccurring = () => {
    setIsInnerLoader2(true);
    const data = {
      scheduleId: params?.id,
      weekDay: Day,
      frequency: frequency,
      start_date: moment(startTime).format("YYYY-MM-DD"),
      end_date: moment(endTime).format("YYYY-MM-DD"),
    };
    makePostRequest(URLS.getScheduleAutoReoccurringTimes, null, data, null)
      .then((res) => {
        setNewDataList(res?.data);
        setIsInnerLoader2(false);
      })
      .catch((error) => {
        setIsInnerLoader2(false);
        console.log(error);
      });
  };
  useEffect(() => {
    if (minDate > dateWise) {
      SetdateList([]);
    }
    // eslint-disable-next-line
  }, [dateWise])
  useEffect(() => {
    setDataList([]);
    if (Day !== "" && frequency !== "" && startTime !== "" && endTime !== "") {
      getSchedualAutoReoccurring();
    }
    // eslint-disable-next-line
  }, [Day, frequency, startTime, endTime]);

  // thuis function use for selcte date
  const onUserSelect = (
    time,
    startEnd,
    endTime,
    newDate,
    isEdit = false,
    list,
    isUperEdit,
    isUp
  ) => {
    if (isEdit === true || isExtraEdit === true) {
      let dummy = list ? { ...list } : { ...dateList };
      let data = dummy?.sessions_timing?.map((v) =>
        v?.[startEnd] === time
          ? { ...v, isSelected: true }
          : { ...v, isSelected: false }
      );
      SetdateList({ ...dummy, sessions_timing: data });
      let dataListDummy = [...dataList2];
      let nnn = newDate ? newDate : dateWise;
      let dummy2 = dataListDummy?.find(
        (v) => v?.schedule_date === moment(nnn).format("YYYY-MM-DD")
      );
      if (dummy2?.schedule_date) {
        let du = dataListDummy?.map((v) =>
          v?.schedule_date === moment(nnn).format("YYYY-MM-DD")
            ? { ...v, start_time: time, end_time: endTime }
            : v
        );
        dataListDummy = du;
      } else {
        dataListDummy.push({
          schedule_date: moment(nnn).format("YYYY-MM-DD"),
          start_time: time,
          end_time: endTime,
        });
      }
      setDataList2(dataListDummy);
      if (isUp === true) {
        window.scrollTo(0, 0);
      } else {
      }
    } else {

      if (isUp === true) {
        window.scrollTo(0, 0);
      }
      if (!isUperEdit) {
        let nnn = newDate ? newDate : dateWise;
        let dataListDummy1 = [...dataList];
        let dataListDummy = [];
        if (typeof editIndex === "number") {
          let du = dataListDummy1?.find(
            (v) => (v?.session_date === moment(nnn).format() ||
              v?.session_date === moment(nnn).format("YYYY-MM-DD")) &&
              v?.session_time === time &&
              v?.session_end_time === endTime
          );
          if (du) return toastifyMessage("You have already selected this timeslot.", "error");
          dataListDummy = dataListDummy1?.filter(
            (v, index) => removeDate !== index
          );
        } else {
          dataListDummy = [...dataList];
        }
        let dummy = { ...dateList };
        let data = dummy?.sessions_timing?.map((v) =>
          v?.[startEnd] === time
            ? { ...v, isSelected: !v?.isSelected }
            : { ...v }
        );
        SetdateList({ ...dummy, sessions_timing: data });
        let dummy2 = dataListDummy?.find(
          (v) =>
            (v?.session_date === moment(nnn).format() ||
              v?.session_date === moment(nnn).format("YYYY-MM-DD")) &&
            v?.session_time === time &&
            v?.session_end_time === endTime
        );

        if (dummy2) {
          let dummy = { ...dateList };
          let data = dummy?.sessions_timing?.map((v) => ({
            ...v,
            isSelected: false,
          }));
          SetdateList({ ...dummy, sessions_timing: data });
          return toastifyMessage("You have already selected this timeslot.", "error");
        } else {
          dataListDummy.push({
            session_date: moment(nnn).format("YYYY-MM-DD"),
            session_time: time,
            session_end_time: endTime,
          });
        }
        setDataList(dataListDummy);
      } else {
        let dummy = { ...dateList };
        let data = dummy?.sessions_timing?.map((v) =>
          v?.[startEnd] === time
            ? { ...v, isSelected: true }
            : { ...v, isSelected: false }
        );
        SetdateList({ ...dummy, sessions_timing: data });

        let dataListDummy = [...dataList];
        let nnn = newDate ? newDate : dateWise;
        let dummy2 = dataListDummy?.find(
          (v) =>
            (v?.session_date === moment(nnn).format() ||
              v?.session_date === moment(nnn).format("YYYY-MM-DD")) &&
            v?.session_time === time &&
            v?.session_end_time === endTime
        );
        if (dummy2) {
          let du = dataListDummy?.map((v) =>
            v?.session_date === moment(nnn).format("YYYY-MM-DD") &&
              v?.session_time === time &&
              v?.session_end_time === endTime
              ? { ...v, session_time: time, session_end_time: endTime }
              : v
          );
          dataListDummy = du;
        } else {
          dataListDummy.push({
            session_date: moment(nnn).format("YYYY-MM-DD"),
            session_time: time,
            session_end_time: endTime,
          });
        }
        setDataList(dataListDummy);
      }
    }
    if (isEdit === false && isExtraEdit === false && !isUperEdit) {
      if (typeof editIndex == "number") {
        let dummy = { ...dateList };
        let data = dummy?.sessions_timing?.map((v) => ({
          ...v,
          isSelected: false,
        }));
        SetdateList({ ...dummy, sessions_timing: data });
        setEditIndex("");
      }
      toastifyMessage("Session selected sucessfully.", "success");
    }
  };
  // this function use for select book date
  const onCountinue = () => {
    setIsLoading(true);
    if (gradeLevelIds?.length === 0) {
      setIsLoading(false);
      return toastifyMessage("Please select student", "error");
    }
    let data = [
      {
        scheduleId: params?.id,
        child_ids: gradeLevelIds?.map((v) => v.value),
        session_type: "manual",
        session_date_time: dataList,
      },
      ...cart.scheduleData,
    ];
    const uniqueDataArray = data;
    let body = { ...cart, scheduleData: uniqueDataArray };
    const uniqueData = body.scheduleData.reduce((acc, currentItem) => {
      const existingItem = acc.find(
        (item) =>
          item.scheduleId === currentItem.scheduleId &&
          item.session_type === currentItem.session_type &&
          item.session_date_time[0].session_date === currentItem.session_date_time[0].session_date &&
          item.session_date_time[0].session_time === currentItem.session_date_time[0].session_time
      );
      if (existingItem) {
        existingItem.child_ids.push(...currentItem.child_ids);
      } else {
        acc.push({ ...currentItem, child_ids: [...currentItem.child_ids] });
      }
      return acc;
    }, []);
    makePostRequest(URLS.saveMS, null, { type: type ? "paynow" : "paynow", save_type: type ? "paynow" : cart?.save_type, scheduleData: uniqueData }, null)
      .then((res) => {
        toastifyMessage(res?.message, "success");
        setIsLoading(false);
        if (res?.code === 200) {
          if (res?.data?.save_type === "free") {
            localStorage.setItem("unique_id", res?.data?.payment_intent);
            localStorage.setItem("zeroPrice", "yes");
            navigate("/success")
          }
          setCart(body);
          localStorage.setItem(type ? "" : "cart", JSON.stringify(res?.data?.postedData));
          dispatch(incrementByAmount(body.scheduleData.length));
          setOtherData([
            ...otherData,
            {
              image: masterData?.scheduleData?.[0]?.tbl_user?.photo,
              resource: masterData?.scheduleData?.[0]?.tbl_user?.firstName + " " + masterData?.scheduleData?.[0]?.tbl_user?.lastName,
              mentor: masterData?.scheduleData?.[0]?.tbl_user_resource?.resource_title,
              duration: masterData?.scheduleData?.[0]?.duration,
              price: masterData?.scheduleData?.[0]?.price?.toFixed(2),
              scheduleId: params?.id,
              child: gradeLevelIds?.map((v) => v.label),
              child_ids: gradeLevelIds?.map((v) => v.value),
            },
          ]);
          localStorage.setItem(type ? "signupData" : "otherData",
            JSON.stringify([
              ...otherData,
              {
                image: masterData?.scheduleData?.[0]?.tbl_user?.photo,
                resource:
                  masterData?.scheduleData?.[0]?.tbl_user?.firstName +
                  " " +
                  masterData?.scheduleData?.[0]?.tbl_user?.lastName,
                mentor:
                  masterData?.scheduleData?.[0]?.tbl_user_resource
                    ?.resource_title,
                duration: masterData?.scheduleData?.[0]?.duration,
                price: type ? 0 : masterData?.scheduleData?.[0]?.price?.toFixed(2),
                scheduleId: params?.id,
                child: gradeLevelIds?.map((v) => v.label),
                child_ids: gradeLevelIds?.map((v) => v.value),
              },
            ])
          );
          type ? localStorage.removeItem("sessionSignId") : localStorage.setItem("sessionId", params?.id)
          type ? localStorage.removeItem("signstate") : localStorage.setItem("state", JSON.stringify(res?.data));
          !type && navigate("/purchase-page-card/no");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toastifyMessage(JSON.parse(CryptoJS.AES.decrypt(
          error?.response?.data?.data,
          secretKey
        ).toString(CryptoJS.enc.Utf8))?.message, "error");
        console.log("error", error);
      });
  };
  // this function use for select book date
  const onCountinue2 = () => {
    setIsLoading(true);
    if (gradeLevelIds?.length === 0) {
      setIsLoading(false);
      return toastifyMessage("Please select student", "error");
    }
    let data = [
      {
        scheduleId: params?.id,
        child_ids: gradeLevelIds?.map((v) => v.value),
        session_type: "auto",
        session_date_time: newSelectedTime?.map((v) => ({
          session_date: v?.session_date,
          session_time: v?.session_time,
          session_end_time: v?.session_end_time,
        })),
        day: Day,
        frequency: frequency,
        start_date: startTime,
        end_date: endTime,
      },
      ...cart.scheduleData,
    ];
    const uniqueDataArray = data;
    let body = { ...cart, scheduleData: uniqueDataArray };
    const uniqueData = body.scheduleData.reduce((acc, currentItem) => {
      const existingItem = acc.find(
        (item) =>
          item.scheduleId === currentItem.scheduleId &&
          item.session_type === currentItem.session_type &&
          item.session_date_time[0].session_date === currentItem.session_date_time[0].session_date &&
          item.session_date_time[0].session_time === currentItem.session_date_time[0].session_time
      );
      if (existingItem) {
        existingItem.child_ids.push(...currentItem.child_ids);
      } else {
        acc.push({ ...currentItem, child_ids: [...currentItem.child_ids] });
      }

      return acc;
    }, []);
    makePostRequest(URLS.saveMS, null, { type: type ? "paynow" : "paynow", save_type: type ? "paynow" : cart?.save_type, scheduleData: uniqueData }, null)
      .then((res) => {
        toastifyMessage(res?.message, "success");
        setIsLoading(false);
        if (res?.code === 200) {
          if (res?.data?.save_type === "free") {
            localStorage.setItem("unique_id", res?.data?.payment_intent);
            localStorage.setItem("zeroPrice", "yes");
            navigate("/success")
          }
          setCart(body);
          localStorage.setItem(type ? "" : "cart", JSON.stringify(body));
          dispatch(incrementByAmount(body.scheduleData.length));
          setOtherData([
            ...otherData,
            {
              image: masterData?.scheduleData?.[0]?.tbl_user?.photo,
              resource:
                masterData?.scheduleData?.[0]?.tbl_user?.firstName +
                " " +
                masterData?.scheduleData?.[0]?.tbl_user?.lastName,
              mentor:
                masterData?.scheduleData?.[0]?.tbl_user_resource?.resource_title,
              duration: masterData?.scheduleData?.[0]?.duration,
              price: masterData?.scheduleData?.[0]?.price?.toFixed(2),
              scheduleId: params?.id,
              child: gradeLevelIds?.map((v) => v.label),
              child_ids: gradeLevelIds?.map((v) => v.value),
            },
          ]);
          localStorage.setItem(type ? "signupData" : "otherData",
            JSON.stringify([
              ...otherData,
              {
                image: masterData?.scheduleData?.[0]?.tbl_user?.photo,
                resource:
                  masterData?.scheduleData?.[0]?.tbl_user?.firstName +
                  " " +
                  masterData?.scheduleData?.[0]?.tbl_user?.lastName,
                mentor:
                  masterData?.scheduleData?.[0]?.tbl_user_resource
                    ?.resource_title,
                duration: masterData?.scheduleData?.[0]?.duration,
                price: type ? 0 : masterData?.scheduleData?.[0]?.price?.toFixed(2),
                scheduleId: params?.id,
                child: gradeLevelIds?.map((v) => v.label),
                child_ids: gradeLevelIds?.map((v) => v.value),
              },
            ])
          );
          type ? localStorage.removeItem("sessionSignId") : localStorage.setItem("sessionId", params?.id)
          type ? localStorage.removeItem("signstate") : localStorage.setItem("state", JSON.stringify(res?.data));
          !type && navigate("/purchase-page-card/no");

        }
      })
      .catch((error) => {
        console.log(error);
        toastifyMessage(error?.message, "error");
        setIsLoading(false);
      });
  };

  // these function use for remove 
  const removeFromList = () => {
    let dummy = [...dataList];
    let dd = dummy?.filter((v, index) => removeDate !== index);
    setDataList(dd);
    toastifyMessage("Session removed sucessfully", "success");
    setOpen(false);
    let dummy1 = { ...dateList };
    let dd1 = dateList?.sessions_timing?.map((v) => ({
      ...v,
      isSelected: false,
    }));
    SetdateList({ ...dummy1, sessions_timing: dd1 });
  };
  const editFromList = async (date, start, end, index) => {
    setEditIndex(index);
    window.scrollTo(0, 300);
    setDateWise(new Date(date));
    let dummy = { ...dateList };
    let data = dummy?.sessions_timing?.map((v) =>
      v?.["start"] === start
        ? { ...v, isSelected: true }
        : { ...v, isSelected: false }
    );
    SetdateList({ ...dummy, sessions_timing: data });
    let dataListDummy = [...dataList];
    let nnn = new Date(date) ? new Date(date) : dateWise;
    let dummy2 = dataListDummy?.find(
      (v) =>
        (v?.session_date === moment(nnn).format() ||
          v?.session_date === moment(nnn).format("YYYY-MM-DD")) &&
        v?.session_time === start &&
        v?.session_end_time === end
    );
    if (dummy2) {
      let du = dataListDummy?.map((v) =>
        v?.session_date === moment(nnn).format("YYYY-MM-DD") &&
          v?.session_time === start &&
          v?.session_end_time === end
          ? { ...v, session_time: start, session_end_time: end }
          : v
      );
      dataListDummy = du;
    } else {
      dataListDummy.push({
        session_date: moment(nnn).format("YYYY-MM-DD"),
        session_time: start,
        session_end_time: end,
      });
    }
    setDataList(dataListDummy);
  };
  useEffect(() => {
    setDataList([]);
    let dummy = { ...dateList };
    let dd = dateList?.sessions_timing?.map((v) => ({
      ...v,
      isSelected: false,
    }));
    SetdateList({ ...dummy, sessions_timing: dd });
    // eslint-disable-next-line
  }, [Day, frequency, startTime, endTime]);

  // this function use for new select date
  const onNewSelect = (start, end, date) => {
    let dummy = [...newSelectedTime];
    let alreadyExist = dummy?.find(
      (v) =>
        v?.session_date === date &&
        v?.session_time === start &&
        v?.session_end_time === end
    );
    if (alreadyExist) {
      let vvv = dummy?.filter(
        (v) => JSON.stringify(v) !== JSON.stringify(alreadyExist)
      );
      setNewSelectedTime(vvv);
    } else {
      setNewSelectedTime([
        ...dummy,
        {
          session_date: date,
          session_time: start,
          session_end_time: end,
          isSelected: true,
        },
      ]);
    }
    let dummy2 = [...newDataList];
    let findDate = dummy2?.find((v) => v?.scheduleDate === date);
    let neee = findDate?.scheduleTimeData?.map((v) =>
      v?.start_time === start ? { ...v, isSelected: !v?.isSelected } : { ...v }
    );
    let bee = dummy2?.map((v) =>
      v?.scheduleDate === date ? { ...v, scheduleTimeData: neee } : v
    );
    setNewDataList(bee);
  };

  // this function use for disable dates
  const disabledDate = (args) => {
    let date = masterData?.scheduleData?.[0]?.scheduleDates?.map(
      (v) => v?.schedule_date
    );
    
   date.sort((a, b) => new Date(a) - new Date(b));
    if (!date?.includes(moment(args?.date).format("YYYY-MM-DD"))) {
      args.isDisabled = true;
    }
    if (!minDate) setMinDate(date?.length > 0 ? new Date(date[0]) : null)
    if (!dateWise) setDateWise(date?.length > 0 ? new Date(date[0]) : null)
  };
  useEffect(() => {
    onDateSelect()
    // eslint-disable-next-line
  }, [minDate, dateWise])
  useEffect(() => {
    setDataList([])
    setNewDataList([])
  }, [tabNumber]);

  useEffect(() => {
    getUserChilds();
    getDataByid();
    document.body.classList.add("bookasession");
    return () => {
      document.body.classList.remove("bookasession");
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="col-lg-10 paddingcontrighs bookasessionmentoringsession">
        <div className="tobarbackcontee">
          <div className="row gobackcontainer">
            <h5
              className="col-lg-2 moniertindmobiles c-pointer"
              onClick={() => navigate(-1)}
            >
              <span>
                <i className="fa fa-angle-left" aria-hidden="true"></i>
              </span>{" "}
              Go Back
            </h5>
            <div className="col-lg-5 col-12 moniertindmobiles bookss">
              <div className="bookappominatsmeeting ">
                <h5>Book a Session </h5>
              </div>
            </div>
            <div className="col-lg-5 col-12 moniertindmobiles">
            </div>
          </div>
        </div>
        {isLoading ? (
          <ul>
            <li>
              <div className="sharepopupOuter backgroundOpacity1">
                <FourDotsLoader />
              </div>
            </li>
          </ul>
        ) : masterData ? (
          <>
            <div className="row">
              <div className="col-lg-10">
                <div className="manual-session-div manual-session-div-inner">
                  <ul>
                    <li>
                      <div className="sprash-name">
                        <div className="sprash-name-lt">
                          <img
                            className="img-fluid"
                            src={masterData?.scheduleData?.[0]?.tbl_user?.photo}
                            alt="user"
                          />
                        </div>
                        <div className="sprash-name-rts">
                          <h3>
                            {masterData?.scheduleData?.[0]?.tbl_user?.firstName}{" "}
                            {masterData?.scheduleData?.[0]?.tbl_user?.lastName}{" "}
                          </h3>
                          <p>
                            {
                              masterData?.scheduleData?.[0]?.tbl_user_resource
                                ?.resource_title
                            }
                          </p>
                        </div>
                      </div>
                      <div className="sprash-name-rt">
                        <div className="sprash-name-box">
                          <h4> Schedule Type</h4>
                          <p>
                            {masterData?.scheduleData?.[0]?.schedule_type === "1"
                              ? "Group Session"
                              : masterData?.scheduleData?.[0]?.schedule_type ===
                                "2"
                                ? "Individual Session"
                                : "-"}{" "}
                          </p>
                        </div>
                        <div className="sprash-name-box">
                          <h4> Duration</h4>
                          <p>
                            {
                              masterData?.scheduleData?.[0]?.duration
                            } minutes{" "}
                          </p>
                        </div>
                        {
                          !type && <div className="sprash-name-box">
                            <h4> Price</h4>
                            <p>
                              ${masterData?.scheduleData?.[0]?.price?.toFixed(2)}{" "}
                            </p>
                          </div>
                        }
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <Tabs defaultIndex={tabNumber} onSelect={(index) => setTabNumber(index)}>
              <TabList>
                <Tab>
                  <h3 className="mt-0">Single Pick Date</h3>
                </Tab>
                <Tab>
                  <h3 className="mt-0">Reoccurring Bookings</h3>
                </Tab>
              </TabList>
              <div className="row">
                <div className="col-6 col-md-4">
                  <div className="tobarbackcontee">
                  </div>
                </div>
              </div>
              <TabPanel>
                <div className="mannualdatesection">
                  <div className="row">
                    <div className="col-lg-8 col-sm-6 col-10 mannuldatemb">
                      <div className="innerboxdatesst">
                        <div className="checkboxdiv">
                          <div className="form-group">
                            <label>Single Pick Date</label>
                          </div>
                        </div>
                        <div className="datebackground">
                          {masterData?.scheduleData && (
                            <CalendarComponent
                              id="calendar"
                              min={new Date()}
                              onChange={(e) => {
                                setDay("");
                                setFrequency("");
                                setStartTime("");
                                setendTime("");
                                setNewDataList([]);
                                onDateSelect(e);
                              }}
                              value={dateWise}
                              renderDayCell={disabledDate}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-8 mannuldatemb">
                      <div className="picktimecalendaer ">
                        <h3>
                          Pick a time{" "}
                        </h3>
                        <span
                            style={{
                              fontSize: "16px",
                           
                            }}
                          >
                            ({payLoad?.replace("_", " ")})
                          </span>
                        <div className="mentorSessionmultipleselection picktimeselectHeight">
                          <Select
                            value={
                              gradeLevelIds && gradeLevelIds.length > 0
                                ? gradeLevelIds
                                : null
                            }
                            options={gradeLevelData}
                            placeholder="select student"
                            isMulti
                            selectMultiple={true}
                            onChange={(gradeVal) => setGradeLevelIds(gradeVal)}
                          />
                        </div>
                        <div className="picktimeRightSide">
                          <div className="mysidebrscroll square scrollbar-dusty-grass square thin" ref={chatContainerRef}>
                            {isInnerLoader ? (
                              <ul>
                                <li>
                                  <div className="">
                                    <FourDotsLoader />
                                  </div>
                                </li>
                              </ul>
                            ) : (
                              <ul>
                                {tabNumber === 0 && dateList?.sessions_timing?.length ? (
                                  dateList?.sessions_timing?.map((v) => (
                                    <>
                                      <li>
                                        <Link
                                          to=""
                                          referrerPolicy="no-referrer"
                                          className={`${v?.status === "notavailable" &&
                                            "notavailable"
                                            } ${v?.isSelected && "selectedTime"
                                            } c-pointer`}
                                          onClick={() =>
                                            onUserSelect(
                                              v?.start,
                                              "start",
                                              v?.end
                                            )
                                          }
                                        >
                                          <span>
                                            {moment(
                                              v?.start,
                                              "h:mm:ss A"
                                            ).format("hh:mm A")}
                                          </span>
                                        </Link>
                                      </li>
                                    </>
                                  ))
                                ) : (
                                  <h1>Time slot not available!</h1>
                                )}
                              </ul>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="autorecordingsection">
                  <div className="row">
                    <div className="col-lg-8">
                      <div className="autorecordingmb">
                        <div className="autorecordingform">
                          <div className="checkboxdiv">
                            <div className="mentorSessionmultipleselection recuringBookingselectHeight mb-3">
                              <Select
                                value={
                                  gradeLevelIds && gradeLevelIds.length > 0
                                    ? gradeLevelIds
                                    : null
                                }
                                options={gradeLevelData}
                                placeholder="select student"
                                isMulti
                                selectMultiple={true}
                                onChange={(gradeVal) => setGradeLevelIds(gradeVal)}
                              />
                            </div>
                            <div className="form-group">
                              <label>Reoccurring Bookings</label>
                            </div>
                          </div>
                          <form>
                            <div className="row">
                              <div className="col-lg-6">
                                <div className="mb-3">
                                  <label className="form-label">Start Date</label>
                                  <DatePickerComponent
                                    id={`timepicker`}
                                    name={`startTime`}
                                    ref={datepickerRef}
                                    onFocus={handleInputClick}
                                    placeholder="Select a date"
                                    value={startTime}
                                    min={moment(new Date()).format("YYYY-MM-DD")}
                                    onChange={(e) => {
                                      if (
                                        endTime &&
                                        endTime < e.target.currentDate
                                      ) {
                                        setendTime(e.target.currentDate);
                                        setStartTime(e.target.currentDate);
                                      } else {
                                        setStartTime(e.target.currentDate);
                                      }
                                    }}
                                    renderDayCell={disabledDate}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="mb-3">
                                  <label className="form-label">End Date</label>
                                  <DatePickerComponent
                                    id={`timepicker2`}
                                    name={`endTime`}
                                    ref={datepickerRef2}
                                    onFocus={handleInputClick2}
                                    placeholder="Select a date"
                                    value={endTime}
                                    min={
                                      new Date(startTime) ||
                                      moment(new Date()).format("YYYY-MM-DD")
                                    }
                                    onChange={(e) =>
                                      setendTime(e.target.currentDate)
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg-6">
                                <div className="mb-3">
                                  <label className="form-label ">Day</label>
                                  <div className="moniertindmobiles">
                                    <select
                                      className="jackey"
                                      placeholder="day"
                                      value={Day}
                                      onChange={(e) => setDay(e.target.value)}
                                    >
                                      <option selected="">Select day</option>
                                      <option selected="Sunday">Sunday</option>
                                      <option selected="Monday">Monday</option>
                                      <option selected="Tuesday">Tuesday</option>
                                      <option selected="Wednesday">
                                        Wednesday
                                      </option>
                                      <option selected="Thursday">
                                        Thursday
                                      </option>
                                      <option selected="Friday">Friday</option>
                                      <option selected="Saturday">
                                        Saturday
                                      </option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="mb-3">
                                  <label className="form-label moniertindmobiles">
                                    Frequency
                                  </label>
                                  <select
                                    className="jackey"
                                    placeholder="frequency"
                                    value={frequency}
                                    onChange={(e) => setFrequency(e.target.value)}
                                  >
                                    <option selected="">Select frequency</option>
                                    <option selected="Weekly">Weekly</option>
                                    <option selected="Monthly">Monthly</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPanel>
            </Tabs>
            <div className="row selectsessions">
              <div className="col-lg-8 autorecordingmb bg-white">
                {
                  isExtraEdit === false && dataList?.length > 0 && (
                    <div className="selectsessionspd">
                      <h3> Selected Sessions: </h3>
                      <ul>
                        {dataList
                          ?.sort(
                            (a, b) =>
                              new Date(a?.session_date) -
                              new Date(b?.session_date)
                          )
                          ?.map((v, index) => (
                            <li>
                              <div className="selectsessionslt">
                                <p>
                                  {" "}
                                  <span> Date: </span>{" "}
                                  {moment(v?.session_date).format("MM-DD-YYYY")}
                                </p>
                                <p>
                                  {" "}
                                  <span> Time: </span>{" "}
                                  {moment(v?.session_time, "h:mm:ss A").format(
                                    "hh:mm A"
                                  )}{" "}
                                  -{" "}
                                  {moment(
                                    v?.session_end_time,
                                    "h:mm:ss A"
                                  ).format("hh:mm A")}{" "}
                                </p>
                              </div>
                              <div className="selectsessionsrt">
                                <Link
                                  to=""
                                  onClick={() => {
                                    setOpen(true);
                                    setRemoveDate(index);
                                  }}
                                  referrerPolicy="no-referrer"
                                  className="removebutton c-pointer"
                                >
                                  Remove
                                </Link>
                                <Link
                                  to=""
                                  onClick={() => {
                                    setRemoveDate(index);
                                    editFromList(
                                      v?.session_date,
                                      v?.session_time,
                                      v?.session_end_time,
                                      index
                                    );
                                  }}
                                  referrerPolicy="no-referrer"
                                  className="removebutton c-pointer"
                                >
                                  Edit{" "}
                                </Link>
                              </div>
                            </li>
                          ))}
                      </ul>
                      <div className="cancelbuttonflex">
                        <button
                          onClick={onCountinue}
                          className="contiundeflexbutton c-pointer"
                        >
                          Continue{" "}
                        </button>&nbsp;&nbsp;
                        {
                          type ? <button
                            onClick={onCountinue}
                            className="contiundeflexbutton c-pointer"
                          >
                            Sign Up{" "}
                          </button> : ""
                        }
                      </div>
                    </div>
                  )
                }
                {isInnerLoader2 ? (
                  <ul>
                    <li>
                      <div className="">
                        <FourDotsLoader />
                      </div>
                    </li>
                  </ul>
                ) : newDataList?.length > 0 && dataList?.length <= 0 ? (
                  <div
                    className="selectsessionspd"
                    style={{ boxShadow: "none" }}
                  >
                    <h3> Available Slots: </h3>
                    <ul>
                      {newDataList?.map((v, i) => (
                        <li>
                          <h1>
                            {moment(v?.scheduleDate).format("MM-DD-YYYY")}
                          </h1>
                          <div style={{ flexWrap: "wrap", display: "flex" }}>
                            {v?.scheduleTimeData?.map((k) => (
                              <li className="width-fit">
                                {" "}
                                <button
                                  className={` ${k?.isSelected && "selectedTime"
                                    } c-pointer`}
                                  onClick={() =>
                                    onNewSelect(
                                      k?.start_time,
                                      k?.end_time,
                                      v?.scheduleDate
                                    )
                                  }
                                >
                                  <span>
                                    {moment(k?.start_time, "h:mm:ss A").format(
                                      "hh:mm A"
                                    )}
                                  </span>
                                </button>
                              </li>
                            ))}
                          </div>
                        </li>
                      ))}
                    </ul>
                    <div className="cancelbuttonflex">
                      <button
                        onClick={onCountinue2}
                        className="contiundeflexbutton c-pointer"
                      >
                        Continue{" "}
                      </button>
                      {
                        type ? <button
                          onClick={onCountinue2}
                          className="contiundeflexbutton c-pointer"
                        >
                          Sign Up{" "}
                        </button> : ""
                      }
                    </div>
                  </div>
                ) : (newDataList?.length === 0 && dataList?.length <= 0 && frequency && Day && startTime && endTime) ? (
                  <>
                    <h1 style={{ padding: "10px" }}>
                      Time slot not available!
                    </h1>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          </>
        ) : (
          <h1
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh",
            }}
          >
            No Data Found
          </h1>
        )}
      </div>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="text-center"
            style={{ color: "#2D3134", fontSize: "20px", lineHeight: "36px" }}
          >
            Are you sure you want to remove this session?
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 3 }}
            className="d-flex justify-content-center gap-4"
          >
            <button
              className="child-save"
              type="button"
              onClick={() => removeFromList()}
            >
              Yes
            </button>{" "}
            <button className="child-delete" onClick={() => setOpen(false)}>
              No
            </button>{" "}
          </Typography>
        </Box>
      </Modal>
    </>
  );
}
export default BookASessionManualpicking;
