/**
 * Sale  History  page to show all sale resources history
 * @author Nidhi Mishra <nidhi.mishra@webappmate.com>
 * created on: 16-06-2023
 */
import { useState, useEffect } from "react";
import "../BookaSession.WebAppMate/BookaSession.css";
import { makePostRequest, imageUrl, makePostRequestForAll } from "../../services/api";
import { URLS } from "../../constants";
import { decodeToken, toastifyMessage, forceDownload, getResourceImage, } from "../../utilities/CustomFunctions";
import moment from "moment";
import { useNavigate, Link, useLocation } from "react-router-dom";
import FourDotsLoader from "../Common.WebAppMate/FourDoted";
import { Paper, Tab, Tabs } from "@mui/material";
import { Month, Week, Day, ScheduleComponent, ViewsDirective, ViewDirective, Inject } from '@syncfusion/ej2-react-schedule';
import SingleChat from "../Chat.WebAppMate/SingleChat";
import { useSelector, useDispatch } from 'react-redux';
import { getOpenGlobalChat } from '../../store/reducers/userReducer';
import DatePicker from "react-datepicker";
import Multiselect from "multiselect-react-dropdown";

function SaleHistory() {
  let uimage = imageUrl;
  const dispatch = useDispatch()
  let userStore = useSelector((state) => state.user);
  const userId = decodeToken().userAuth.id;
  let useractive = localStorage.getItem("useractive");
  let navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location?.search || null);
  const [loading, setLoading] = useState(false);
  const [saleHistoryData, setSaleHistoryData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(0);
  const [value, setValue] = useState("resource");
  const [currentView, setCurrentView] = useState('Month');
  const [curremtDate, setCurremtDate] = useState(new Date())
  const [data1, setData1] = useState([])
  const [endDate, setEndDate] = useState("");
  const [startDate, setStartDate] = useState("")
  const [resourceId, setResourceId] = useState("")
  const [resourceData, setResourceData] = useState([]);
  const [showView, seShowView] = useState(false);
  const [message, setMessage] = useState("")
  const eventSettings = { dataSource: data1 }

  //api call to get all sale resources history list
  useEffect(() => {
    const getSaleHistory = () => {
      setLoading(true);
      let data = {
        page_number: currentPage,
        end_date: (endDate && moment(endDate).format("YYYY-MM-DD")) || "",
        start_date: (startDate && moment(startDate).format("YYYY-MM-DD")) || ""
      };
      makePostRequest(URLS.getAllSaleResourcesHistory, null, data, null)
        .then((res) => {
          if (res.code === 200) {
            setLoading(false);
            if (currentPage === 1) {
              setSaleHistoryData(res?.data?.data);
              setHasMore(res?.data?.remaining_count);
            } else {
              setSaleHistoryData([...saleHistoryData, ...res?.data?.data]);
              setHasMore(res?.data?.remaining_count);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    getSaleHistory();
    // eslint-disable-next-line
  }, [startDate, endDate, currentPage]);

  useEffect(() => {
    const param1 = queryParams.get('ref') || null;
    const param3 = queryParams.get('date') || null;
    const inputDate = moment(param3);
    const outputDateString = inputDate.format('ddd MMM DD YYYY HH:mm:ss [GMT]Z');
    if (param1 === "session") {
      setValue("session")
      setCurremtDate(outputDateString)
    }
    if (queryParams.get('go')) {
      setValue("session")
      setCurremtDate(outputDateString)
    }
    const param12 = queryParams.get('resource');
    if (param12) {
      setResourceId(parseInt(param12));
    }
    // eslint-disable-next-line
  }, [location?.search])

  //download invoice
  const downloadReceipt = (invoicePdf) => {
    if (invoicePdf) {
      let pdfFileName = invoicePdf.split("/");
      forceDownload(uimage + invoicePdf, pdfFileName[3]);
    } else {
      toastifyMessage("No invoice available for download", "error");
    }
  };
  // this function use for get resource
  const getResource = () => {
    makePostRequest(URLS.vendorResource, null, {}, null)
      .then((res) => {
        const arr = [{ value: "", label: "All" }]
        res.data.forEach((val) => {
          arr.push({ value: val.tbl_user_resource.id, label: val.tbl_user_resource.resource_title })
        })
        setResourceData(arr)
      }).catch((error) => {
        console.log("error", error)
      })
  }
  useEffect(() => {
    getResource()
    if (useractive === "") {
      navigate("/sign-in");
    }
    document.body.classList.add("servepaymenthistory");
    return () => {
      document.body.classList.remove("servepaymenthistory");
    };
  }, [navigate, useractive]);

  // useEffect(() => {
  //   // This effect runs whenever location (URL) changes so chat window not open again when you come back on this page again
  //   dispatch(getOpenGlobalChat(
  //     {chatOpen : false, userDetail : "", userMapId: "", senderId: "", group: "", pageName : "" })) // Reset or clear the userstore data here
  // }, [location.pathname]);

  const onNavigating = (args) => {
    try {
      setCurremtDate(args?.currentDate)
      if (args?.currentView) {
        setCurrentView(args?.currentView);
      } else {
        setCurremtDate(args?.currentDate)
      }
    } catch (error) {
      console.log("eror::::", error)
    }
  };
  // appy color on selecte date
  function applyCategoryColor(args, currentView) {
    var categoryColor = args.data && args.data.categoryColor;
    if (!args.element || !categoryColor) {
      return;
    }
    if (currentView === 'Agenda') {
      const firstChild = args.element.firstChild;
      if (firstChild) {
        firstChild.style.borderLeftColor = categoryColor;
      }
    } else if (currentView === 'Month') {
      // Handle Month view differently, e.g., change the background color of the entire cell
      args.element.style.backgroundColor = categoryColor;
    } else if (args.element && showView) {
      // Handle other views
      args.element.style.backgroundColor = categoryColor;
    }
  }
  const onEventRendered = (args) => {
    try {
      applyCategoryColor(args, currentView);
    } catch (error) {
      console.log("error::", error)
    }
  }
  const onPopupOpen = (args) => {
    try {
      console.log("args", args)
      if (args?.data?.IsReadOnly === true) {
        args.cancel = false
      } else if (args?.type === "EventContainer") {
        args.cancel = false;
      } else {
        args.cancel = true;
      }
    } catch (error) {
      console.log("error", error)
    }
  }
  // header template
  const headerTemplate = (props) => {
    return (<div className="quick-info-header">
      <div className="quick-info-header-content" style={{
        alignItems: 'left', color: '#919191', paddingLeft: "0",
        paddingBottom: "0"
      }}>
        <div className="quick-info-title">{props?.resurceName}</div>   </div>
      <div className="quick-info-title">student : {props?.childName}</div>
      <div className="quick-info-title">{props?.date + " (" + moment(props?.StartTime).format("hh:mm A") + " - " + moment(props?.EndTime).format("hh:mm A") + ")"}</div>
    </div>);
  }
  // contant template
  const contentTemplate = (props) => {
    return (<div className="quick-info-content">
      <div className="event-content">
        <div className=" meeting-subject-wrap">
          <label>Ref. Number:</label>
          <span>{props?.refNumber}</span>
        </div>
        {
          props?.amount && <div className="meeting-subject-wrap">
            <label>Amount :</label>
            <span>{props?.amount}</span>
          </div>
        }
        {
          props?.status === "Partial" ? <div className="notes-wrap">
            <label>Payment Status :</label>
            <span>Partial Refund</span>
          </div> : <> {props?.status === "full_refund" ?
            <div className="notes-wrap">
              <label>Payment Status :</label>
              <span>Full Refund</span>
            </div>
            : <>{
              props?.amount ? <div className="notes-wrap">
                <label>Payment Status :</label>
                <span>{props?.status}</span>
              </div> : ""
            }</>
          }
          </>
        }
        <div className="notes-wrap">
          <label>Booking Status :</label>
          <span>{props?.bookingStatus}</span>
        </div>
      </div>
    </div>);
  }
  // footer template
  const footerTemplate = (props) => {
    return (<div className="quick-info-footer">
    </div>);
  }
  const quickInfoTemplates = {
    header: headerTemplate,
    content: contentTemplate,
    footer: footerTemplate
  };

  // this function use for get sale history
  useEffect(() => {
    const getData = async (cv) => {
      function getCurrentWeekDates(today) {
        const dayOfWeek = today.getDay(); // 0 (Sunday) to 6 (Saturday)
        const firstDayOfWeek = new Date(today);
        firstDayOfWeek.setDate(today.getDate() - dayOfWeek); // Subtract day of the week
        const lastDayOfWeek = new Date(today);
        lastDayOfWeek.setDate(today.getDate() + (6 - dayOfWeek)); // Add to reach end of week
        return { firstDayOfWeek, lastDayOfWeek };
      }
      const { firstDayOfWeek, lastDayOfWeek } = getCurrentWeekDates(new Date(curremtDate));
      function getCurrentMonthDates(currentDate) {
        const firstDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        const lastDay = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
        return { firstDay, lastDay };
      }
      const { firstDay, lastDay } = getCurrentMonthDates(new Date(curremtDate));
      setLoading(true)
      seShowView(false)
      let body = {
        "start_date": currentView === "Day" ? moment(curremtDate).format("YYYY-MM-DD") :
          currentView === "Week" ? moment(firstDayOfWeek).format("YYYY-MM-DD") :
            currentView === "Month" ? moment(firstDay).format("YYYY-MM-DD") : moment(new Date()).format("YYYY-MM-DD"),
        "end_date": currentView === "Day" ? moment(curremtDate).format("YYYY-MM-DD") :
          currentView === "Week" ? moment(lastDayOfWeek).format("YYYY-MM-DD") : currentView === "Month" ?
            moment(lastDay).format("YYYY-MM-DD") : moment(new Date()).format("YYYY-MM-DD"),
        "resource_id": resourceId || "",
        "schedule_type": ""
      }
      let body2 = {
        "start_date": cv === "Day" ? moment(curremtDate).format("YYYY-MM-DD") :
          cv === "Week" ? moment(firstDayOfWeek).format("YYYY-MM-DD") : cv === "Month" ?
            moment(firstDay).format("YYYY-MM-DD") : moment(new Date()).format("YYYY-MM-DD"),
        "end_date": cv === "Day" ? moment(curremtDate).format("YYYY-MM-DD") :
          cv === "Week" ? moment(lastDayOfWeek).format("YYYY-MM-DD") : cv === "Month" ?
            moment(lastDay).format("YYYY-MM-DD") : moment(new Date()).format("YYYY-MM-DD"),
        "resource_id": resourceId || "",
        "schedule_type": ""
      }
      console.log("cv", cv, cv ? body2 : body)
      await makePostRequest(URLS.getMSDataVendor, null, cv ? body2 : body, null)
        .then((response) => {
          setLoading(false)
          if (response?.data?.event.length > 0) {
            let arr = []
            response?.data?.event?.map(v => (
              v?.tbl_user_mentoring_session_datetimes?.map(k => (arr.push({
                Id: v?.id,
                Subject: k?.booking_status === "Cancelled" ? "Cancelled" : v?.tbl_child?.firstName + " " + v?.tbl_child?.lastName,
                StartTime: moment(k?.session_date + "T" + k?.session_time).format('YYYY-MM-DDTHH:mm:ssZ'),
                EndTime: moment(k?.session_date + "T" + k?.session_end_time).format('YYYY-MM-DDTHH:mm:ssZ'),
                date: moment(k?.session_date).format("MM-DD-YYYY"),
                resurceName: v?.tbl_user_resource?.resource_title,
                IsReadOnly: true,
                categoryColor: k ? (k.booking_status === "Cancelled") ? "#F19357" : "#265472" : "#265472",
                key: "avlbl",
                RoomId: v?.resource_id,
                parentName: "sss",
                childName: v?.tbl_child?.firstName + " " + v?.tbl_child?.lastName,
                childId: v?.tbl_child?.id,
                amount: v?.price_per_slot?.toFixed(2) > 0 ? "$" + v?.price_per_slot?.toFixed(2) : "",
                status: k?.status,
                refNumber: v?.tbl_payment_history?.payment_reference_no,
                session_datetime_id: k?.id,
                scheduleId: v?.scheduleId,
                bookingStatus: (k.booking_status === "Cancelled") ? "Cancelled" : v?.price_per_slot?.toFixed(2) > 0 ? k?.booking_status : "successful",
              })))
            ))
            setMessage("")
            setData1(arr)
            seShowView(true)
          } else {
            setData1([])
            setMessage("No data history is available in this month.");
          }

        }).catch(e => console.log(e))
    }
    curremtDate && getData();
    // eslint-disable-next-line
  }, [currentView, curremtDate, resourceId]);

  // THIS FUNCTION USE FOR CHAT
  const AddUser = (id, userData) => {
    dispatch(getOpenGlobalChat({ ...userStore?.globalOpenChat, chatOpen: false }))
    makePostRequestForAll(URLS.addChatUser, { chat_with: id })
      .then((res) => {
        if (res.code === 200) {
          dispatch(getOpenGlobalChat({ ...userStore?.globalOpenChat, chatOpen: true, userMapId: res?.data?.id, userDetail: userData, senderId: id, group: 0, pageName : "salehistory", chat_by_block : res?.data?.block, 
            block_by_other_person : res?.data?.chat_by_block,
            chat_with_block: res?.data?.chat_with_block,
          }))
        }
      })
      .catch((e) => {
        console.log("error:::::", e)
      });
  };

  return (
    <>
      {loading && (
        <div className="sharepopupOuter backgroundOpacity1">
          <FourDotsLoader />
        </div>
      )}
      <div className="col-lg-10">
        <div>
          {
           userStore?.globalOpenChat?.chatOpen && userStore?.globalOpenChat?.pageName === "salehistory" &&
           <SingleChat statuspage={"salehistory"} groupName={userStore?.globalOpenChat?.userDetail} chatClose={() =>    dispatch(getOpenGlobalChat({
            chatOpen: false, userDetail: "", userMapId: "", senderId: "", group: "", pageName : ""
             }))} userMapId={userStore?.globalOpenChat?.userMapId} senderId={userStore?.globalOpenChat?.senderId} group={userStore?.globalOpenChat?.group} getAllUser={() => { }} />
          }
        </div>
        <div className="tobarbackcontee">
          <div className="gobackcontainer">
            <h5 className="col-6">
              <Link to="/serve-dashboard" className="link-style">
                <span>
                  <i className="fa fa-angle-left" aria-hidden="true"></i>
                </span>{" "}
                Go Back
              </Link>
            </h5>
          </div>
        </div>
        <div className="bookmoniterdiv borderbottomss">
          <div className="row">
            <div className="col-lg-8">
              <h2>Sales History </h2>
            </div>
            {
              value === "resource" && <div className="row">
                <div className="col-lg-12">
                  <div style={{ float: "right" }}>
                    <div className="select-exp-div">
                      <DatePicker
                        className="border-0 w-100 searchDate"
                        id=""
                        selected={startDate}
                        onChange={(date) => { setStartDate(date); setCurrentPage(1) }}
                        placeholderText="Start Date"
                        dateFormat="MM-dd-yyyy"
                      />
                    </div>
                    <div className="select-exp-div">
                      <DatePicker
                        className="border-0 w-100 searchDate"
                        id="date"
                        selected={endDate}
                        onChange={(date) => { setEndDate(date); setCurrentPage(1) }}
                        placeholderText="End Date"
                        dateFormat="MM-dd-yyyy"
                      />
                    </div>
                  </div>
                </div>
              </div>
            }
            {
              (value === "session" && resourceData.length > 0) &&
              <div className="row">
                <div className="col-lg-12">
                  <div style={{ float: "right" }}>
                    <div className="col-lg-4">
                      <div className="saveResourceChildSelects">
                        <div className="salesHistoryrightSelect">
                          <Multiselect
                            options={resourceData}
                            onSelect={(selectedList) => {
                              selectedList?.forEach((val, i) => {
                                setResourceId(val.value);
                              });
                            }}
                            onRemove={(selectedList) => {
                              selectedList?.forEach((val, i) => {
                                setResourceId(val.value);
                              });
                            }}
                            selectedValues={resourceId ? resourceData.filter(item => item.value === resourceId) : [{value: '', label: 'All'}]}
                            showCheckbox
                            displayValue="label"
                            placeholder="Select Resource"
                            showArrow={true}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
            <div>
              <Paper square>
                <Tabs
                  value={value}
                  textColor="primary"
                  indicatorColor="primary"
                  onChange={(event, newValue) => {
                    setValue(newValue);
                  }}
                >
                  <Tab label="Resource History" value="resource" />
                  <Tab label="Session History" value="session" />
                </Tabs>
              </Paper>
            </div>
            <div className="col-lg-4 menroginturorsds">
              <div className="resourcetypess">
              </div>
              <div className="tobarbackcontee float-end">
                <div className="gobackcontainer"></div>
              </div>
            </div>
          </div>
          {value === "resource" && <div className="bookmoniterdivbox">
            <ul>
              {saleHistoryData?.length > 0 &&
                saleHistoryData.map((val, i) => {
                  return (
                    <li key={i + 1}>
                      <div className="sprash-name">
                        <div className="sprash-name-lt cursor-pointer" onClick={() => navigate("/full-description/" + val?.resource_id)}>
                          <div className={`img-divsr${val?.tbl_user_resource?.resource_type}`}>
                            {getResourceImage(val?.tbl_user_resource?.resource_type)}
                          </div>
                        </div>
                        <div className="sprash-name-rts cursor-pointer" onClick={() => navigate("/full-description/" + val?.resource_id)}>
                          <h3>{val?.tbl_user_resource?.resource_title} </h3>
                        </div>
                      </div>
                      <div className="sprash-name-rt">
                        <div className="sprash-name-box">
                          <h4> Funding Available</h4>
                          <p> {val?.tbl_user_resource?.public_funding} </p>
                        </div>
                        <div className="sprash-name-box">
                          <h4> Purchase Date</h4>
                          <p>
                            {" "}
                            {moment(val?.created_at).format("MM-DD-YYYY")}{" "}
                          </p>
                        </div>
                        <div className="sprash-name-box">
                          <h4> Amount</h4>
                          <p> ${Number(val?.total_amount).toFixed(2)}</p>
                        </div>
                        <div className="sprash-name-box">
                          <button
                            className="invoicebtn"
                            onClick={() => {
                              downloadReceipt(
                                val?.tbl_payment_history?.invoice_pdf_link
                              );
                            }}
                          >
                            Invoice
                          </button>
                          {
                            val?.tbl_user?.id !== userId ? <button
                              className="invoicebtn"
                              onClick={() => {
                                AddUser(val?.tbl_user?.id, { "name": val?.tbl_user?.firstName + val?.tbl_user?.lastName, "image": uimage + val?.tbl_user?.photo })
                               }}
                            >
                              Chat
                            </button>
                              : <button className="invoicebtn" >
                                User
                              </button>}
                        </div>
                      </div>
                    </li>
                  );
                })}
              {saleHistoryData?.length === 0 && (
                <>
                  <li>Currently no sales history.</li>
                </>
              )}
            </ul>
            {hasMore > 0 && (
              <div className="text-center w-100">
                <button
                  className="border-0 btn-md btn"
                  onClick={() => setCurrentPage((prevPage) => prevPage + 1)}
                >
                  Load More
                </button>
              </div>
            )}
          </div>}

          {value === "session" && <div className="bookmoniterdivbox">
            <h4 className="text-center text-danger">{message}</h4>
            <ScheduleComponent
              width='100%' height='435px' currentView='Month' selectedDate={curremtDate}
              cssClass='quick-info-template'
              eventSettings={eventSettings}
              eventRendered={onEventRendered}
              navigating={onNavigating}
              popupOpen={onPopupOpen}
              disabled={true}
              readOnly={true}
              timeFormat='hh:mm a'
              quickInfoTemplates={quickInfoTemplates}
            >
              <ViewsDirective>
                <ViewDirective option='Day' />
                <ViewDirective option='Week' />
                <ViewDirective option='Month' />
              </ViewsDirective>
              <Inject services={[Day, Week, Month]} />
            </ScheduleComponent>
          </div>}
        </div>
      </div>
    </>
  );
}
export default SaleHistory;
