import React from "react";
import './Notification.css';
import { useState, useEffect } from "react";
import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from "react-headless-accordion";
import FourDotsLoader from "../Common.WebAppMate/FourDoted";
import { makeGetRequest, makePostRequest } from "../../services/api";
import { URLS } from "../../constants";

//      * Notification function is used to do view all the notification
//      *
//      * @author Upendra Kumar <upendra.webappmate@gmail.com>
//      * created on: 05-5-2023
//      * api implimentation by @author krishna dobariya  <krishna.webappmate@gmail.com>

const NotificationSettings = () => {
	const [showspin, setshowspin] = useState(false);
	const [NotificationTitle, setNotificationTitle] = useState([])

	// handle status of notification
	const handleInput = (data) => {
		makePostRequest(URLS.updateNotification, null, data, null)
			.then((res) => {
			}).catch((error) => {
				console.log("error:::::", error)
			})
	}

	// this function create to det all notification
	useEffect(() => {
		const getNotification = async () => {
			setshowspin(true)
			makeGetRequest(URLS.getAllnotification, null, null, null)
				.then((res) => {
					console.log("res:::::", res)
					setshowspin(false)
					setNotificationTitle(res.data)
				}).catch((error) => {
					setshowspin(false)
					console.log("error::::", error)
				})
		}
		getNotification()
		document.body.classList.add("notificationSetting");
		return () => {
			document.body.classList.remove("notificationSetting");
		};
	}, []);
	
	return (<>
		<div className="col-lg-10 col-md-12 col-sm-12">
			<div className="notification-header">
				<h1>Notifications Settings</h1>
			</div>
			{showspin &&
				<div className="sharepopupOuter backgroundOpacity1">
					<FourDotsLoader />
				</div>
			}
			<div className="notificationsettingBox">
				<Accordion>
					{NotificationTitle &&
						NotificationTitle.map((val, index) => {
							return (
								<>
									<AccordionItem key={index}>
										{({ open }) => (
											<>
												<AccordionHeader className={`accordion-title-main accordion-title ${open ? 'accordion-active' : ''}`}>
													<div className={`accordion-titles`}>
														{val.notification_group_name}
													</div>
												</AccordionHeader>
												<AccordionBody className='accordion-body-main'>
													<Accordion>
														{
															val?.tbl_notification_masters.map((val, index) => {
																return (
																	<>
																		<AccordionItem key={index}>
																			{({ open }) => (<>
																				<AccordionHeader className={`accordion-title ${open ? 'accordion-active' : ''}`}>
																					<div className={`accordion-titles`}>
																						{val.meassge_type}
																					</div>
																				</AccordionHeader>
																				<AccordionBody>
																					<div className="accordion-data-notification">
																						<ul className="notiListbox">
																							<li>
																								<div className="text-infos">
																									<span>
																										<i className="fa fa-bell"></i> Push
																										{/* <TableViewIcon /><span className="mr-2">Push</span> */}
																									</span>
																								</div>
																								<div className="notification-switch form-check form-switch form-switch-sm">
																									{
																										val?.tbl_notification_user?.message_push_status === "enable" ? <input className="form-check-input" type="checkbox"
																											defaultChecked
																											id="flexSwitchCheckDefault" onChange={() => handleInput({ "id": val?.tbl_notification_user?.id, "message_push_status": "disable" })} /> : <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" onChange={() => handleInput({ "id": val?.tbl_notification_user?.id, "message_push_status": "enable" })} />
																									}
																								</div>
																							</li>
																							<li>
																								<div className="text-infos">
																									<span><i className="fa fa-envelope"></i> Email
																										{/* <MailIcon /><span className="mr-2">Email</span> */}
																									</span>
																								</div>
																								<div className="notification-switch form-check form-switch form-switch-sm">
																									{
																										val?.tbl_notification_user?.message_email_status === "enable" ? <input className="form-check-input" type="checkbox"
																											defaultChecked
																											id="flexSwitchCheckDefault" onChange={() => handleInput({ "id": val?.tbl_notification_user?.id, "message_email_status": "disable" })} /> : <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" onChange={() => handleInput({ "id": val?.tbl_notification_user?.id, "message_email_status": "enable" })} />
																									}
																								</div>
																							</li>
																							<li>
																								<div className="text-infos">
																									<span>
																										<i className="fa fa-paper-plane"></i> SMS
																										{/* <SmsIcon /> <span className="ml-2">Sms</span> */}
																									</span>
																								</div>
																								<div className="notification-switch form-check form-switch form-switch-sm">
																									{
																										val?.tbl_notification_user?.message_sms_status === "enable" ? <input className="form-check-input" type="checkbox"
																											defaultChecked
																											id="flexSwitchCheckDefault" onChange={() => handleInput({ "id": val?.tbl_notification_user?.id, "message_sms_status": "disable" })} /> : <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" onChange={() => handleInput({ "id": val?.tbl_notification_user?.id, "message_sms_status": "enable" })} />
																									}
																								</div>
																							</li>
																						</ul>
																					</div>
																				</AccordionBody>
																			</>)}

																		</AccordionItem>
																	</>
																)
															})
														}
													</Accordion>
												</AccordionBody>
											</>
										)}
									</AccordionItem>
								</>
							);
						})}
				</Accordion>
			</div>
		</div >
	</>);
}
export default NotificationSettings;