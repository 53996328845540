import React from "react";
import { useState } from "react";
import Threedotspopup from "../Common.WebAppMate/Threedotspopup";
import { Link, useLocation, useNavigate } from "react-router-dom";
import PermissionSharepopup from "../ShareInspiration.WebAppMate/Permissonsharepopup";
import { useEffect, useRef } from "react";
import {
  imageUrl,
  makePostRequest,
  makePostRequestForAll,
} from "../../services/api";
import { URLS } from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import { decodeToken } from "../../utilities/CustomFunctions";
import "../CreateGroup.WebAppMate/Sidebar1.css"
import "./sidebar.css"
import InviteFriendpopup from "../Common.WebAppMate/InviteFriendpopup";
import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from "react-headless-accordion";
import PermissionRemovepopup from "../ShareInspiration.WebAppMate/RemovePermisson";
import SingleChat from "../Chat.WebAppMate/SingleChat";
import { saveUserChildrens } from "../../store/reducers/userReducer";
import { getOpenGlobalChat } from '../../store/reducers/userReducer';

function Sidebar() {
  let dispatch = useDispatch();
  const usersrote = useSelector((store) => store.user)
  let uimage = imageUrl;
  let user_id = decodeToken()?.userAuth?.id;
  let userStore = useSelector((state) => state.user);
  const [show, setshow] = useState(false);
  const [bgColor] = useState("");
  const [sideData, setSideData] = useState([]);
  const [RoportPopup, setReportPopup] = useState(false);
  const [newFriend, setNewFrined] = useState("");
  const [matchId, setMatchId] = useState("");
  const [sharpermision, setSharepermision] = useState(false);
  const [removePermissions, setRemovePermissions] = useState(false)
  const [filter, setfilter] = useState("");
  const [searchItem, setSearchItem] = useState("");
  const [sharepermisiondata, setsharepermissiondata] = useState([]);
  const [checkCount, setCheckCount] = useState([]);
  const navigate = useNavigate();
  const [scroll, setScroll] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const [heightstate, setHeightstate] = useState(true);
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const containerHeight = useRef(null);
  const menuRef = useRef(null);
  const menuBtnRef = useRef(null);

  const handleReportpopups = () => {
    setReportPopup(!RoportPopup);
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    setIsMounted(true);
    return () => {
      setIsMounted(false);
    };
  }, []);
  // this function use for handle scroll
  const handleScroll = () => {
    if (
      window.innerHeight + Math.round(document.documentElement.scrollTop) >= document.documentElement.offsetHeight) {
      setHeightstate(false)
    } else {
      setHeightstate(true)
    }
  };

  const permision = () => {
    setSharepermision(true);
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  };
  const removePermission = () => {
    setRemovePermissions(true);
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }
  useEffect(() => {
    setTimeout(() => {
      isMounted && getStudent()
      isMounted && getFriendlist()
    }, 1000);
    // eslint-disable-next-line
  }, [isMounted]);

  // this function use for get student list
  const getStudent = () => {
    var payload = {
      resource_id: "",
    };
    makePostRequest(URLS.getMyChildListSide, null, payload, null).then((res) => {
      if (res?.code === 200) {
        const childOwner0 = res.data.filter(obj => obj.childOwner === 0 && obj.isAdmin === 1);
        const childOwner1 = res.data.filter(obj => obj.childOwner === 1);
        setSideData(childOwner1);
        setsharepermissiondata(childOwner0);
        dispatch(saveUserChildrens(res.data));
      }
    });

    console.warn = () => { };
    console.error = () => { };
  }
  // this function use for get friend list
  const getFriendlist = () => {
    if (user_id) {
      makePostRequestForAll(URLS.newFriendRequest, { user_id: user_id })
        .then((res) => {
          if (res !== undefined && res.code === 200) {
            setCheckCount(res.data.friendList.length)
            setNewFrined(res.data.friendList);
            setfilter(res.data.friendList);
          }
        })
    }
  }

  // -------search friend--------
  const searchHandle = (e) => {
    const searchItem = e.target.value.toLowerCase();
    if (filter) {
      const filtered = filter.filter(
        (res) =>
          res.name.toLowerCase().includes(searchItem) ||
          res.email.toLowerCase().includes(searchItem)
      );
      setNewFrined(filtered);
      setSearchItem(searchItem);
    }
  };

  const handleClickOutside = (e) => {
    if (
      menuRef?.current &&
      !menuRef?.current?.contains(e.target) &&
      !menuBtnRef?.current?.contains(e.target)
    ) {
      setshow(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside, true);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    const childOwner0 = usersrote?.childList?.filter(obj => obj.childOwner === 0 && obj.isAdmin === 1);
    const childOwner1 = usersrote?.childList?.filter(obj => obj.childOwner === 1);
    setSideData(childOwner1);
    setsharepermissiondata(childOwner0);
  }, [usersrote.childList])

  useEffect(() => {
    setCheckCount(usersrote?.friendList?.friendList?.length)
    setNewFrined(usersrote?.friendList?.friendList);
    setfilter(usersrote?.friendList?.friendList);
  }, [usersrote.friendList])  

  // this function use for add user in chat room
  const AddUser = (id, userData) => {
    dispatch(getOpenGlobalChat({ ...userStore?.globalOpenChat, chatOpen: false }))
    makePostRequestForAll(URLS.addChatUser, { chat_with: id })
      .then((res) => {
        if (res.code === 200) {
          dispatch(getOpenGlobalChat({ ...userStore?.globalOpenChat, chatOpen: true, userMapId: res?.data?.id, userDetail: userData, senderId: id, group: 0, pageName: "sidebar", chat_by_block : res?.data?.block, 
            block_by_other_person : res?.data?.chat_by_block,   chat_with_block: res?.data?.chat_with_block,
           }))
        }
      })
      .catch((e) => {
        console.log("error:::::", e)
      });
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 40);
    });
  }, []);

  return (
    <>
      <div className={`sidebar ${scroll ? "sidebarfix" : "sidebarscroll"}`}>
        <div className="search-div-2">
          <ul className="sidebarlink">
            <li>
              <Link
                className={splitLocation[1] === "search-page" ? "active" : ""}
                to="/search-page"
              >
                Search
                <i className="fa fa-info-circles">i
                  <div className="tooltipinfo">
                    <ul>
                      <li><span className="searchboldcolor">Resources</span>: Printed or digital books, curriculum, online learning, experiences, schools, clubs, tutors, coaches, parent/teacher support.  </li>
                      <li><span className="searchboldcolor">Global Search</span>: Consolidated search across multiple platforms.</li>
                      <li><span className="searchboldcolor">Customized</span>: Personalized results sorted by learning preferences.</li>
                      <li><span className="searchboldcolor">Filtered Search</span>: See what your Friends and Groups have used and reviewed.</li>
                    </ul>
                  </div>
                </i>
              </Link>
            </li>
            <li>
              <Link
                className={
                  splitLocation[1] === "save-resources-list" || splitLocation[1] === "save-page" ? "active" : ""
                }
                to="/save-resources-list"
              >
                Save
                <i className="fa fa-info-circles">i
                  <div className="tooltipinfo">
                    <ul>
                      <li><span className="saveboldcolor">
                        Student Portfolio</span>: Each student has their own portfolio sorted by grade.
                      </li>
                      <li><span className="saveboldcolor">Artifacts</span>: Store pictures, videos, work samples and memories. </li>
                      <li><span className="saveboldcolor">Resources</span>: Record the educational resourced used for each student for sharing or reporting.</li>
                      <li><span className="saveboldcolor">Rate, Review, Share</span>: Rate and Review your saved resources to share with your Friends and Groups</li>
                    </ul>
                  </div>
                </i>
              </Link>
            </li>
            <li>
              <Link
                className={
                 splitLocation [1] === "share-inspiration" ? "active" : ""
                }
                to="/share-inspiration"
              >
                {" "}
                Share
                <i className="fa fa-info-circles">i
                  <div className="tooltipinfo">
                    <ul>
                      <li >
                        <span className="shareboldcolor">Share to Community</span>: Ask questions, Start a chat, post an event or resource, or send encouragement to your Friends, Groups, or Local MatchED members.
                      </li>

                      <li>
                        <span className="shareboldcolor">Shared with Me</span>: Stay current with a list of recently shared resources and  events from your Friends, Groups, and Local MatchED Members.
                      </li>
                      <li><span className="shareboldcolor">Local Events</span>: Stay updated on local events and gatherings in your community posted by other MatchED members.</li>
                    </ul>
                  </div>
                </i>
              </Link>
            </li>
            <li>
              <Link
                className={
                  splitLocation[1] === "serve-dashboard" || splitLocation[1] === "serve-your-resources" || splitLocation[1] === "serve" || splitLocation[1] === "server-payment-history" || splitLocation[1] === "sale-history" || splitLocation[1] === "create-schedule" ? "active" : ""
                }
                to="/serve-dashboard"
              >
                Serve
                <i className="fa fa-info-circles">i
                  <div className="tooltipinfo">
                    <ul>
                      <li><span className="serveboldcolor">Post Resources</span>: Organize local field trips and meetings, post services you provide (lessons, coaching, counseling, tutoring), provide mentoring.</li>
                      <li><span className="serveboldcolor">Share</span>: Share resources you offer with Friends, Groups, Local MatchED members or the Global MatchED community.</li>
                      <li><span className="serveboldcolor">Scheduling</span>: Schedule availability and sign-up for services and events</li>
                      <li><span className="serveboldcolor">
                        Payment Processing</span>: Process any fees for services or events.
                      </li>
                    </ul>
                  </div>
                </i>
              </Link>
            </li>
          </ul>
          <div className={`${heightstate === true ? "sideListAccordianTitle" : "sideListNew"}`} ref={containerHeight}>
            <div className="myprofilelist">
              <div className="accordion">
                <span className="target-fix" id="accordion"></span>
                <div>
                  <span className="target-fix" id="accordion1"></span>
                  <a href="#accordion1" id="open-accordion1" title="open" ><h4 className="my-profile-h my-2">My Students</h4></a>
                  <a href="#accordion" id="close-accordion1" title="close"><h4 className="my-profile-h my-2">My Students</h4></a>
                  <div className="accordion-content myprofileInneraccordion">
                    <div className="mysidebrscrolls square scrollbar-dusty-grass square thin">
                      <div className="card-body">
                        <Accordion>
                          {sideData &&
                            sideData.map((val, index) => {
                              return (
                                <>
                                  <AccordionItem key={index}>
                                    {({ open }) => (
                                      <>
                                        <AccordionHeader className={`accordion-title ${open ? 'accordion-active' : ''}`}>
                                          <div className={`accordion-title profile-division ${open ? 'accordion-active' : ''}`}>
                                            <img
                                              src={uimage + val.photo}
                                              className="img-jackey rounded-circle"
                                              alt="student"
                                            />
                                            <h5 className="jackey-name">
                                              <Link>
                                                {val.firstName} {val.lastName}
                                              </Link>
                                            </h5>
                                          </div>
                                        </AccordionHeader>
                                        <AccordionBody className="bodycontentAccordion">
                                          <div className="accordion-body content-div1">
                                            <ul>
                                              <li>
                                                <Link
                                                  className={
                                                    splitLocation[1] === `child-profile-page` ? "activex" : ""
                                                  }
                                                  to={`/child-profile-page/${val.id}`}
                                                >
                                                  View Portfolio
                                                </Link>
                                              </li>
                                              <li>
                                                <Link
                                                  className={
                                                    splitLocation[1] === `server-payment-history` ? "activex" : ""
                                                  }
                                                  to={`/server-payment-history/${val.id}`}
                                                >
                                                  Purchase History
                                                </Link>
                                              </li>
                                              <li>
                                                <Link
                                                  className={
                                                    splitLocation[1] === `child-info` ? "activex" : ""
                                                  }
                                                  to={`/child-info/${index}`}
                                                >
                                                  Profile Settings
                                                </Link>
                                              </li>
                                            </ul>
                                          </div>
                                        </AccordionBody>

                                      </>
                                    )}

                                  </AccordionItem>
                                </>
                              );
                            })}
                        </Accordion>
                        {sharepermisiondata &&
                          sharepermisiondata.map((val, index) => {
                            return (
                              <div className="profile-division my-1" key={index}>
                                <img
                                  src={uimage + val?.photo}
                                  className="img-jackey rounded-circle"
                                  alt="student"
                                />
                                <h5
                                  className="jackey-name"
                                  style={{
                                    color: `${bgColor.id === index ? "white" : "#5B5F62"} `,
                                  }}
                                >
                                  <Link
                                    to={`/child-profile-update-view/${val.id}`}
                                  >
                                    {val.firstName} {val.lastName}
                                  </Link>
                                </h5>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion myfriendaccordion">
              <span className="target-fix" id="accordion2"></span>
              <h2 className="my-profile-h my-2 side-pad" onClick={() => navigate("/purchase-pages-friend")} style={{ cursor: "pointer" }}>My Friends</h2>
              <a href="#accordion2" id="open-accordion2" className="accordian-link2" title="open">
              </a>
              <a href="#accordion" id="close-accordion2" className="accordian-link2" title="close">
              </a>
              <div className="friend-div">
              </div>
              <div className="accordion-content">
                {
                  checkCount <= 0 ? " " :
                    <>
                      <div className="input-div-sidebar">
                        <input
                          type="text"
                          placeholder="Search"
                          className="input-handle"
                          name="searchItem"
                          onChange={searchHandle}
                          value={searchItem}
                        />
                      </div>
                    </>
                }
                <div className="profile-div-user1">
                  <div className="mysidebrscroll square scrollbar-dusty-grass square thin">
                    <div className="card-body">
                      {newFriend &&
                        newFriend?.map((value, index) => {
                          return (
                            <div className="icon-handle" key={index}>
                              <div className="profile-division1">
                                <img src={value.photo} className="img-jackey" alt="mathced user" />
                                <div>
                                  <p className="mt-1 text-capitalize">{value.name}</p>
                                  <button className="chat-btn" onClick={() => {
                                    AddUser(value?.userId,{ "name": value?.name, "image": value?.photo })
                                  }}>Chat</button>
                                </div>
                              </div>
                              <span ref={menuBtnRef}
                                onClick={() => {
                                  setshow(!show);
                                  setMatchId(index);
                                }}
                                className="three-dots"
                              >
                                <i className="fa fa-ellipsis-v"></i>
                                {show === true && matchId === index ? (
                                  <Threedotspopup val={permision} id={value?.userId} remove={removePermission} forwardedref={menuRef} />
                                ) : null}
                              </span>
                              {sharpermision === true && matchId === index ? (
                                <PermissionSharepopup
                                  data={() => setSharepermision(false)}
                                  userId={value.userId}
                                  userName={value.name}
                                />
                              ) : null}
                              {removePermissions && matchId === index ?
                                <PermissionRemovepopup
                                  data={() => setRemovePermissions(false)}
                                  userId={value.userId}
                                  userName={value.name}
                                /> : ""
                              }
                            </div>
                          );
                        })}
                    </div>
                  </div>
                  <div className="friend-invite">
                    <button onClick={handleReportpopups}>Invite Friend</button>
                  </div>
                  {RoportPopup === true ? (
                    <InviteFriendpopup datar={handleReportpopups} />
                  ) : null}
                </div>
              </div>
            </div>
            <div className="gropping-div">
            </div>
            <div className="main-inner-gropping">
              <div className="accordion">
                <span className="target-fix" id="accordion3"></span>
                <h2 onClick={() => navigate("/yourgroup")} className="side-pad pb-3">Groups</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      {
        userStore?.globalOpenChat?.chatOpen && userStore?.globalOpenChat?.pageName === "sidebar" &&
         <SingleChat statuspage={"sidebar"} groupName={userStore?.globalOpenChat?.userDetail} chatClose={() => dispatch(getOpenGlobalChat({
          chatOpen: false, userDetail: "", userMapId: "", senderId: "", group: "", pageName: ""
        }))} userMapId={userStore?.globalOpenChat?.userMapId} senderId={userStore?.globalOpenChat?.senderId} group={userStore?.globalOpenChat?.group} getAllUser={() => { }} />
      }
    </>
  );
}

export default Sidebar;



