import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom';
import { decodeToken, getTimeDifference, toastifyMessage, style } from '../../utilities/CustomFunctions';
import WriteReview from './Writereview';
import "../Search.WebAppMate/rating.css";
import "./rightside.css"
import DeleteMember from './DeleteMember';
import { URLS } from '../../constants';
import { makePostRequest, makePostRequestForAll } from '../../services/api';
import FourDotsLoader from '../Common.WebAppMate/FourDoted';
import SeeAllReview from "./SeeAllReview"
import { useSelector , useDispatch} from 'react-redux';
import { renderRatingStars } from '../Search.WebAppMate/RenderCardInfo';
import LeaveGroupPopup from './LeaveGroupPopup';
import SingleChat from '../Chat.WebAppMate/SingleChat';
import CryptoJS from 'crypto-js';
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { getOpenGlobalChat } from '../../store/reducers/userReducer';
import { useLocation } from 'react-router-dom';
const secretKey = process.env.REACT_APP_DATA_ENCRYPT_DESCRYP_KEY;

const RightSideGroup = (props) => {
    let dispatch = useDispatch();
    const useID = decodeToken().userAuth.id
    const location = useLocation();
    const usersrote = useSelector((store) => store.user)
    const [groupMemberList, setGroupMemberList] = useState([]);
    const [memberRequest, setMemberRequest] = useState([])
    const [writreview, setWritreview] = useState(false);
    const [log, setlog] = useState(false);
    const [search, setSearch] = useState("");
    const [openfrnd, setOpenfrnd] = useState(false);
    const [friendId, setFriendId] = useState("");
    const [index, setIndex] = useState("");
    const [seeAllReviewPopUp, setSeeAllReviewPopUp] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [memberId, setMemberId] = useState("");
    const [spin, setSpin] = useState(false);
    const [page, setPage] = useState(1);
    const [requestPage, setRequestPage] = useState(1);
    const [subGroup, setSubGroup] = useState(1)
    const Navigate = useNavigate();
    const [remainingCount, setRemainingCount] = useState(1);
    const [remainingCountRequest, setRemainingCountRequest] = useState(1);
    const [remainingCountRequest2, setRemainingCountRequest2] = useState(1);
    const [remainingCountSub, setRemainingCountSub] = useState(1)
    const [subGroupList, setSubGroupList] = useState([])
    const [pendingMember, setPendingMember] = useState([])
    const [adminId, setAdminId] = useState([]);
    const [AdminBox, setAdminBox] = useState(false);
    const [joinType, setJoinType] = useState("");
    const [rating, setrating] = useState(0);
    const [groupOpen, setGroupOpen] = useState(false);
    const [lastAdmin, setLastAdmin] = useState("");
    const [totalcount, setTotalReview] = useState("");
    const [chatOpen, setChatOpen] = useState("");
    const [userDetail, setUserDetail] = useState("");
    const [userMapId, setUserMapId] = useState("");
    const [pendingPage, setPendingPage] = useState(1);
    const menuRef = useRef(null);
    const menuBtnRef = useRef(null);


    useEffect(() => {
        setGroupMemberList(usersrote?.groupMemberList)
    }, [usersrote.groupMemberList])

    // -----these are member list functions------------
    const getGroupmember = (id) => {
        setSpin(true)
        const data = {
            "group_id": id,
            "page_number": page,
            "searchKey": search,
            "item_per_page": 6
        }
        makePostRequest(URLS.getGroupMember, null, data, null)
            .then((res) => {
                const arr = []
                res?.data?.groupDetails?.group_admin_users?.map((val) => {
                    return arr.push(val.user_id)
                })
                setAdminId(arr)
                setSpin(false)
                setrating(res?.data?.groupDetails?.overall_rating)
                setTotalReview(res?.data?.groupDetails?.total_review)
                if (page === 1) {
                    setGroupMemberList(res?.data?.groupMemberList)
                }
                else {
                    setGroupMemberList([...groupMemberList, ...res?.data?.groupMemberList]);
                }
                setRemainingCount(res?.data?.remaining_count);
            }).catch((error) => {
                console.log("error:::", error)
                setSpin(false)
            })

    }

    //  this function use for handle scroll
    const handleScroll = (event) => {
        const target = event.target;

        if (target.scrollHeight - target.scrollTop === target.clientHeight) {
            if (remainingCount !== 0) {
                setPage(page + 1);
            }
        }
    };
    const handleSubGroupScroll = (event) => {
        const target = event.target;
        if (target.scrollHeight - target.scrollTop === target.clientHeight) {
            if (remainingCountSub !== 0) {
                setSubGroup(page + 1);
            }
        }
    }

    useEffect(() => {
        props?.id && getGroupmember(props?.id);
        // eslint-disable-next-line
    }, [page])
    // -----end member list functions------------

    // -----this is pending member functions------------
    const getPendingMember = (id) => {
        setSpin(true)
        const data = {
            "group_id": id,
            "searchKey": "",
            "page_number": pendingPage,
            "item_per_page": 4
        }
        makePostRequest(URLS.pendingMemberInvite, null, data, null)
            .then((res) => {
                setSpin(false)
                if (pendingPage === 1) {
                    setPendingMember(res?.data?.groupMemberList)
                }
                else {
                    setPendingMember([...pendingMember, ...res?.data?.groupMemberList]);
                }
                setRemainingCountRequest2(res?.data?.remaining_count);
            }).catch((error) => {
                console.log("getMemberRequest:::", error)
            })


    }
    useEffect(() => {
        props?.id && getPendingMember(props?.id)
        // eslint-disable-next-line
    }, [pendingPage])

    // -----these are member request functions------------
    const getMemberRequest = (id) => {
        setSpin(true)
        const data = {
            "group_id": id,
            "page_number": requestPage,
            "item_per_page": 4
        }
        makePostRequest(URLS.getMemberRequest, null, data, null)
            .then((res) => {
                setSpin(false)
                if (requestPage === 1) {
                    setMemberRequest(res?.data?.groupMemberList)
                }
                else {
                    setMemberRequest([...memberRequest, ...res?.data?.groupMemberList]);
                }
                setRemainingCountRequest(res?.data?.remaining_count);
            }).catch((error) => {
                console.log("getMemberRequest:::", error)
            })
    }
    //this function used for pending member pagination on scroll
    const handlePendingScroll = (event) => {
        const target = event.target;
        if (target.scrollHeight - target.scrollTop === target.clientHeight) {

            if (remainingCountRequest2 !== 0) {

                setPendingPage(pendingPage + 1)
            }
        }
    }

    //this function used for requested member pagination on scroll
    const handleRequestScroll = (event) => {
        const target = event.target;
        if (target.scrollHeight - target.scrollTop === target.clientHeight) {
            if (remainingCountRequest !== 0) {
                setRequestPage(requestPage + 1);
            }
        }
    }

    // this function use for handle friend requeststatus
    const updateStatus = (id, status) => {
        setSpin(true)
        const payload = {
            "request_id": id,
            "status": status
        }
        makePostRequest(URLS.updateJoinStatus, null, payload, null)
            .then((res) => {
                setSpin(false)
                toastifyMessage(res.message, "success");
                getGroupmember(props?.id);
                getMemberRequest(props?.id);
                getSubGroup(props.id)
            }).catch((error) => {
                console.log("error::", error)
            })
    }

    useEffect(() => {
        props?.id && getMemberRequest(props?.id);
        // eslint-disable-next-line
    }, [requestPage])
    // -----end  member request functions------------

    // -----these are subgroup functions------------
    const getSubGroup = (id) => {
        setSpin(true)
        const data = {
            "group_id": id,
            "searchKey": search,
            "page_number": subGroup,
            "item_per_page": 4,
            "joinType": joinType ? "join" : "",
            user_id: useID,
        }
        makePostRequest(URLS.subGroupList, null, data, null)
            .then((res) => {
                setSpin(false)
                if (page === 1) {
                    setSubGroupList(res?.data?.groupMemberList)
                } else {
                    setSubGroupList([...subGroupList, ...res?.data?.groupMemberList]);
                }
                setRemainingCountSub(res?.data?.remaining_count);
            }).catch((error) => {
                setSpin(false)
                console.log("getMemberRequest:::", error)
            })
    }

    useEffect(() => {
        getSubGroup(props.id);
        // eslint-disable-next-line
    }, [subGroup, joinType]);
    // -----end subgroup functions------------

    useEffect(() => {
        setGroupMemberList([])
        props?.id && getGroupmember(props?.id);
        props?.id && getMemberRequest(props?.id);
        props?.id && getSubGroup(props.id);
        props?.id && getPendingMember(props?.id);
        // eslint-disable-next-line
    }, [props?.id])

    const handleClickOutside = (e) => {
        if (
            menuRef?.current &&
            !menuRef?.current?.contains(e.target) &&
            !menuBtnRef?.current?.contains(e.target)
        ) {
            setlog(false);
        }
    };
    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside, true);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside, true);
        };
    }, []);

    // this function use for user connect
    const AddUser = (id, userData) => {
        dispatch(getOpenGlobalChat({ ...usersrote?.globalOpenChat, chatOpen: false }))
        setSpin(true)
        makePostRequestForAll(URLS.addChatUser, { chat_with: id })
            .then((res) => {
                if (res.code === 200) {
                    setUserMapId(res?.data?.id);
                    dispatch(getOpenGlobalChat({ ...usersrote?.globalOpenChat, chatOpen: true, userMapId: res?.data?.id, userDetail: userData, senderId: id, group: 0, pageName: "rightsideGroup",
                        chat_by_block : res?.data?.block, block_by_other_person : res?.data?.chat_by_block,
                        chat_with_block: res?.data?.chat_with_block,
                     }))
                    setSpin(false)
                }
            })
            .catch((e) => {
                console.log("error:::::", e)
                setSpin(false)
                // toastifyMessage("something went wrong.", "error");
            });
    };

    //Invite friend
    const InviteFriend = () => {
        const data = {
            "friend_id": friendId
        }
        makePostRequestForAll(URLS.friendInvite, data)
            .then((res) => {
                if (res.code === 201) {
                    toastifyMessage(res.message, "success");
                    setOpenfrnd(false);
                } else {
                    toastifyMessage(res.message, "warn");
                    setOpenfrnd(false);
                }
            })
    }
    // useEffect(() => {
    //     // This effect runs whenever location (URL) changes so chat window not open again when you come back on this page again
    //     dispatch(getOpenGlobalChat(
    //       {chatOpen : false, userDetail : "", userMapId: "", senderId: "", group: "", pageName : "" })) // Reset or clear the userstore data here
    //   }, [location.pathname]);

    return (
        <>
            {
                spin && <div className="sharepopupOuter backgroundOpacity1"><FourDotsLoader /></div>
            }
            {
                props.isMember &&
                <div className="group-member-div">
                    <div className="accordion border-bottom pt-3 ">
                        <div>
                            <span className="target-fix" id="accordion10"></span>
                            <div className="rating-div" style={{ cursor: "pointer" }}>
                                <h5>Ratings/Reviews</h5>
                            </div>
                            <a href="#accordion10" id="open-accordion10" title="open" className="accordian-link">
                            </a>
                            <a href="#accordion" id="close-accordion10" title="close" className="accordian-link">
                            </a>
                            <div className="accordion-content">
                                <div className="review-div">
                                    <span>{rating !== 0 ? Number(rating).toFixed(1) : "0.0"} {renderRatingStars(rating)} </span>
                                    <span><span className="reviews" onClick={() => setSeeAllReviewPopUp(true)}><span> {totalcount > 0 ? <>({totalcount})</> : "(0)"}{" "}&nbsp;</span>See all reviews</span></span>
                                    <span className="reviews" onClick={() => setWritreview(true)}>Leave a review</span>
                                </div>
                                <div>
                                    {writreview === true ? (
                                        <WriteReview datar={() => setWritreview(!writreview)} value={props.id} state={writreview} rating={(res) => { setrating(res.total_rating); setTotalReview(res.totalcount) }} />
                                    ) : null}</div>
                            </div>
                        </div>

                    </div>
                    <div className="accordion border-bottom pt-3 ">
                        <span className="target-fix" id="accordion11"></span>
                        <div className="Group_mem" style={{ cursor: "pointer" }}>
                            <h5 className='grp_mam_head' onClick={() => Navigate(`/group-members/${props.id}`)}>Group Members</h5>
                        </div>
                        <a href="#accordion11" id="open-accordion11" title="open" className="accordian-link">
                        </a>
                        <a href="#accordion" id="close-accordion11" title="close" className="accordian-link">
                        </a>
                        <div className="accordion-content">
                            <div className="group-mem-div">
                                {
                                    groupMemberList.length > 0 && <div className="search-div">
                                        <input type="text" placeholder="Search" onChange={(e) => setSearch(e.target.value)}></input>
                                        <span className="search-incon" onClick={() => getGroupmember(props.id)}>  <i className="fa fa-search"></i></span>
                                    </div>
                                }
                                <div className="group-member-containersOuter square scrollbar-dusty-grass square thin"
                                    style={{ maxHeight: "150px", overflow: "hidden", overflowY: "auto" }} onScroll={handleScroll}>
                                    <ul className='group-member-containers group-memberNewScrolls'
                                    >
                                        {
                                            groupMemberList && groupMemberList?.map((val, key) => {
                                                return <>
                                                    <li>
                                                        <div className="search-bar-inner">
                                                            <div className="search-image-div">
                                                                <img src={val?.tbl_user?.photo} alt='group img' loading="lazy" />
                                                                <div className="heading-div">
                                                                    <h4 className="text-capitalize">{val?.tbl_user?.firstName} {val?.tbl_user?.lastName}</h4>
                                                                    <p>Joined {getTimeDifference(val?.created_at)}</p>
                                                                </div>
                                                            </div>
                                                            <div className="groupmemberthreedots" ref={menuBtnRef} onClick={() => { setlog(!log); setIndex(key) }}><i className="fa fa-ellipsis-v" /> </div>
                                                            {index === key && log === true && <div className="view-chat" ref={menuRef}>
                                                                {
                                                                    val?.tbl_user?.id !== useID && <>
                                                                        {
                                                                            val.is_friend === "yes" ? <h1 onClick={() => {
                                                                                AddUser(val?.tbl_user?.id, { "name": val?.tbl_user?.firstName + val?.tbl_user?.lastName, "image": val?.tbl_user?.photo })
                                                                                setMemberId(val?.tbl_user?.id);
                                                                                setUserDetail({ "name": val?.tbl_user?.firstName + val?.tbl_user?.lastName, "image": val?.tbl_user?.photo })
                                                                            }}>Chat</h1> : <h1 onClick={() => {
                                                                                setOpenfrnd(true); setFriendId(val?.tbl_user?.id);
                                                                            }}>Invite Friend</h1>
                                                                        }</>
                                                                }
                                                                {
                                                                    val?.tbl_user?.id === useID ? <h1 onClick={() => Navigate(`/parent-registration-form`)}>View Profile</h1> : <h1 onClick={() => Navigate(`/user-profile/${val?.user_id}`)}>View Profile</h1>
                                                                }
                                                                {
                                                                    val?.tbl_user?.id !== useID && <> {adminId.includes(useID) && <> {val?.isAdmin === 0 ?
                                                                        <h1 onClick={() => { setMemberId(val.id); setAdminBox(false); setOpen(true) }}>Remove Member</h1> : <h1 onClick={() => { setMemberId(val.id); setAdminBox(true); setOpen(true) }}>Remove Member</h1>}</>}</>
                                                                }
                                                                {
                                                                    val?.tbl_user?.id === useID && <>
                                                                        {
                                                                            (adminId.includes(useID) && adminId.length === 1) ? <h1 onClick={() => { setGroupOpen(true); setLastAdmin("lastAdmin") }}>Leave Group</h1> :
                                                                                <>
                                                                                    {
                                                                                        adminId.includes(useID) ? <h1 onClick={() => { setGroupOpen(true); setLastAdmin("Admin") }}>Leave Group</h1>
                                                                                            : <h1 onClick={() => { setGroupOpen(true); setLastAdmin("User") }}>Leave Group</h1>
                                                                                    }
                                                                                </>
                                                                        }
                                                                    </>
                                                                }
                                                            </div>
                                                            }
                                                        </div>
                                                    </li>
                                                </>
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        adminId.includes(useID) && <div className="accordion pt-3 border-bottom">
                            <span className="target-fix" id="accordion13"></span>
                            {
                                memberRequest && <>
                                    <div className='d-flex gap-3'>
                                        <h5 className='grp_mam_head' style={{ cursor: "pointer" }} onClick={() => Navigate(`/group-members/${props.id}`, { state: 2 })}>Member Requests</h5>
                                    </div>
                                    <a href="#accordion13" id="open-accordion12" title="open" className="accordian-link"> </a>
                                    <a href="#accordion" id="close-accordion12" title="close" className="accordian-link"> </a>
                                </>
                            }
                            <div className="accordion-content">
                                <div className="group-member-containersOuter square scrollbar-dusty-grass square thin" style={{ maxHeight: "150px", overflow: "hidden", overflowY: "auto" }} onScroll={handleRequestScroll}>
                                    <ul className='group-member-containers' >
                                        {
                                            memberRequest?.length > 0 && memberRequest?.map((val) => {
                                                return <>
                                                    <li>
                                                        <div className="d-flex justify-content-between">
                                                            <div className="member-request d-flex gap-2">
                                                                <img src={val?.tbl_user?.photo} alt='group img' loading="lazy" />
                                                                <h4 className="text-capitalize">{val?.tbl_user?.firstName}</h4>
                                                            </div>
                                                            <div className="member-request-icon">
                                                                <span className="cross-icons " onClick={() => updateStatus(val.id, "Declined")}>
                                                                    <i className="fa fa-times"></i>
                                                                </span>
                                                                <span className="check-icons" onClick={() => updateStatus(val.id, "Accepted")}>
                                                                    <i className="fa fa-check"></i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </li></>
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    }
                    {
                        adminId.includes(useID) && <div className="accordion pt-3 border-bottom">
                            <span className="target-fix" id="accordion14"></span>
                            {
                                pendingMember && <>
                                    <div className='d-flex gap-3'>
                                        <h5 className='grp_mam_head' style={{ cursor: "pointer" }} onClick={() => Navigate(`/group-members/${props.id}`, { state: 5 })}>Pending Member</h5>
                                    </div>
                                    <a href="#accordion14" id="open-accordion14" title="open" className="accordian-link"> </a>
                                    <a href="#accordion" id="close-accordion14" title="close" className="accordian-link"> </a>
                                </>
                            }
                            <div className="accordion-content">
                                <div className="group-member-containersOuter square scrollbar-dusty-grass square thin " style={{ maxHeight: "150px", overflow: "hidden", overflowY: "auto" }} onScroll={handlePendingScroll}>
                                    <ul className='group-member-containers' >
                                        {
                                            pendingMember && pendingMember?.map((val, key) => {
                                                return <>
                                                    <li>
                                                        <div className="d-flex justify-content-between my-2">
                                                            <div className="member-request d-flex gap-2">
                                                                <img src={val?.tbl_user?.photo} alt='group img' loading="lazy" />
                                                                <h4 className="text-capitalize">{val?.tbl_user?.firstName}</h4>
                                                            </div>
                                                            <div className="member-request-icon">
                                                            </div>
                                                        </div>
                                                    </li></>
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="group-mem-div pt-3 border-bottom">
                        <div className="accordion ">
                            <span className="target-fix" id="accordion12"></span>
                            {
                                subGroupList && <>
                                    <div className='d-flex gap-3'>
                                        <h5 className='grp_mam_head' style={{ cursor: "pointer" }} onClick={() => Navigate(`/group-members/${props.id}`, { state: 3 })}>Connected Group</h5>
                                    </div>
                                    <a href="#accordion12" id="open-accordion12" title="open" className="accordian-link"> </a>
                                    <a href="#accordion" id="close-accordion12" title="close" className="accordian-link"> </a>
                                </>
                            }
                            <div className="accordion-content">
                                {
                                    subGroupList && <>
                                        <div className="inviteGpopupinnersub mt-0">
                                            <div className="newgroupmemberselectbox float-start">
                                                <div className='d-flex'>
                                                    <div className="radiosdiv rightradiobtndiv">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="css"
                                                            name="storing_type"
                                                            value=""
                                                            onChange={(event) => setJoinType(event.target.value)}
                                                            defaultChecked={true}
                                                        />
                                                        <label htmlFor="css">Main Group</label>
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="html"
                                                            name="storing_type"
                                                            value="join"
                                                            onChange={(event) => setJoinType(event.target.value)}
                                                        />
                                                        <label htmlFor="html">Sub Group</label>
                                                    </div>
                                                    <div className="facustomeI">
                                                        i
                                                        <div className="tootlippillerinfo">
                                                            <p>Selecting "Main Group" displays the group that your group is a subgroup of. Selecting "Sub Group" displays groups that are subgroups of your group.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }
                                <div className="group-member-containersOuter square scrollbar-dusty-grass square thin" style={{ maxHeight: "150px", overflow: "hidden", overflowY: "auto" }} onScroll={handleSubGroupScroll}>
                                    <ul className='sub-group-member-containers'>
                                        {
                                            subGroupList?.length > 0 ? subGroupList?.map((val, key) => {
                                                return <>
                                                    <li>
                                                        <div className="search-bar-inner">
                                                            <div className="search-image-div">
                                                                <img src={val?.tbl_group?.image} alt='group' loading="lazy" />
                                                                <div className="heading-div">
                                                                    <h4 className="text-capitalize" onClick={() => Navigate(`/groupjoin/${btoa(CryptoJS.AES.encrypt(val?.tbl_group?.id?.toString(), secretKey).toString())}`)} style={{ cursor: "pointer" }}>{val?.tbl_group?.group_name}</h4>
                                                                    <p>Joined {getTimeDifference(val?.last_updated_date)}</p>
                                                                </div>
                                                                <div >
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </>
                                            }) : <h1 className='text-center'>No Data Found</h1>
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    {
                        adminId.includes(useID) && <div className="accordion pt-3 ">
                            <div className='d-flex gap-3'>
                                <h5 className='grp_mam_head' style={{ cursor: "pointer", paddingBottom: "20px" }}
                                    onClick={() => Navigate(`/group-members/${props.id}`)}
                                >Manage Group</h5>
                            </div>
                        </div>
                    }
                </div>
            }
            <DeleteMember
                open={open}
                handleClose={() => setOpen(false)}
                id={memberId}
                funCall={props?.id}
                AdminBox={AdminBox}
            />
            {seeAllReviewPopUp === true ? (
                <SeeAllReview datar={() => setSeeAllReviewPopUp(!seeAllReviewPopUp)} value={props.id} />
            ) : null}
            {
                groupOpen && <LeaveGroupPopup
                    open={groupOpen}
                    handleClose={() => setGroupOpen(false)}
                    value={props?.id}
                    lastAdmin={lastAdmin}
                />
            }
            {
                 usersrote?.globalOpenChat?.chatOpen && usersrote?.globalOpenChat?.pageName === "rightsideGroup" &&
                  <SingleChat statuspage={"rightsideGroup"} groupName={usersrote?.globalOpenChat?.userDetail} 
                 chatClose={() =>  dispatch(getOpenGlobalChat({chatOpen : false, userDetail : "", userMapId: "", senderId: "", group: "", pageName: ""}))}  
                 userMapId={usersrote?.globalOpenChat?.userMapId} senderId={usersrote?.globalOpenChat?.senderId} group={usersrote?.globalOpenChat?.group} getAllUser={() => { }} />
            }
            <Modal
                open={openfrnd}
                onClose={() => setOpenfrnd(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        className="text-center"
                        style={{ color: "#2D3134", fontSize: "30px", lineHeight: "36px" }}
                    >
                        Are you sure you want to invite this friend?
                    </Typography>
                    <Typography
                        id="modal-modal-description"
                        sx={{ mt: 3 }}
                        className="d-flex justify-content-center gap-4"
                    >
                        <button className="child-delete" onClick={InviteFriend} >
                            Yes
                        </button>{" "}
                        <button className="child-cancel" onClick={() => setOpenfrnd(false)}>
                            Cancel
                        </button>
                    </Typography>
                </Box>
            </Modal>
        </>
    )
}
export default RightSideGroup